import { Dispatch, Reducer } from 'redux';
import { notify } from '~/notifications/actions';
import { put } from '~/shared/http';
import { Dependent, DependentsState } from '../state';
import { serializeDependent } from './helpers';

const UPDATE_DEPENDENT_REQUEST = 'dependents/UPDATE_DEPENDENT_REQUEST';
const UPDATE_DEPENDENT_SUCCESS = 'dependents/UPDATE_DEPENDENT_SUCCESS';
const UPDATE_DEPENDENT_FAILURE = 'dependents/UPDATE_DEPENDENT_FAILURE';

const updateDependentRequest = () => ({
  type: UPDATE_DEPENDENT_REQUEST
});

const updateDependentSuccess = (dependent: Dependent) => ({
  type: UPDATE_DEPENDENT_SUCCESS,
  dependent
});

const updateDependentError = () => ({
  type: UPDATE_DEPENDENT_FAILURE
});

export function updateDependent(dependent: Dependent) {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(updateDependentRequest());
    const response = await put(`/api/am/dependents/${dependent.id}`, serializeDependent(dependent));
    if (response.status === 200) {
      await dispatch(updateDependentSuccess(dependent));
      await dispatch(notify(
        'Update family member',
        `${dependent.firstName} was updated successfully`,
        UPDATE_DEPENDENT_SUCCESS,
        'success'));
    }
    else {
      const body = await response.json();
      await dispatch(updateDependentError());
      await dispatch(notify(
        'Update family member',
        `Error updating ${dependent.firstName}: ${body.message || body}`,
        UPDATE_DEPENDENT_FAILURE,
        'error'));
    }
  };
}

export const updateDependentReducer: Reducer<DependentsState> = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_DEPENDENT_REQUEST:
      return {
        ...state,
        updating: true
      };
    case UPDATE_DEPENDENT_SUCCESS:
      return {
        ...state,
        data: state.data.map(d => {
          if (d.id === action.dependent.id) {
            return action.dependent;
          }
          return d;
        }),
        updating: false
      };
    case UPDATE_DEPENDENT_FAILURE:
      return {
        ...state,
        updating: false
      };
    default:
      return state;
  }
};
