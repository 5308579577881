import { Box, PageCenter, Grid, Cell, Spinner, VerticalCell, ErrorPane } from '~/ui';
import React, { ReactNode, useState } from 'react';
import { withLayout } from '~/parentPortal/hoc/withLayout';
import { RegistrationForm } from './RegistrationForm';
import { useRegister } from '../hooks/useRegister';
import { Link } from 'react-router-dom';
import { RegistrationModel } from '../models/RegistrationModel';

export const RegistrationPage = withLayout(null, () => {
  const [status, register] = useRegister();
  const [model, setModel] = useState(new RegistrationModel());

  const submit = (values: RegistrationModel) => {
    setModel(values);
    register(values);
  };

  let content: ReactNode;
  switch (status) {
    case 'requesting':
      content = <PageCenter>
        <Spinner size="medium" />
      </PageCenter>;
      break;
    case 'error':
      content = <>
        <VerticalCell>
          <ErrorPane>
            An unexpected error has occurred. Please try again later...
          </ErrorPane>
        </VerticalCell>
        <RegistrationForm onSubmit={submit} initialValues={model} />
      </>;
      break;
    case 'inUse':
      content = <>
        <VerticalCell>
          <ErrorPane>
            The email address is already in use.
            <br />
            If you have forgotten your password, you can <Link to="/reset-password">reset it</Link> and access your account again.
          </ErrorPane>
        </VerticalCell>
        <RegistrationForm onSubmit={submit} initialValues={model} />
      </>;
      break;
    case 'done':
      content = <VerticalCell>
        Your account has been created and we have sent an email to the address you've provided.
        Please click the link in that email to activate your account.
      </VerticalCell>;
      break;
    default:
      content = <RegistrationForm onSubmit={submit} initialValues={model} />;
  }

  return <PageCenter>
    <Box width="large">
      <Grid>
        <Cell>
          <h1>Registration</h1>
        </Cell>
        {content}
      </Grid>
    </Box>
  </PageCenter>;
});
