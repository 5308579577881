import styled from 'styled-components';
import { Theme } from '../Theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Header = styled.div`
  padding: 20px;
  background: #fff;
  border-bottom: 1px solid ${Theme.LightGrey};
  display: flex;
  align-items: center;

  h1, h2, h3, h4, h5 {
    margin: 0;
  }
`;

export const Content = styled.div`
  flex: 1;
  overflow-y: auto;
`;
