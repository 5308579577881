import styled from 'styled-components';

interface Props {
  cols?: number;
  align?: Align;
}

type Align = 'stretch' | 'flex-start' | 'flex-end';

/**
 * A Grid cell which lays out its content vertically
 */
export const VerticalCell = styled.div<Props>`
  grid-column: span ${p => p.cols || 1};
  display: flex;
  flex-direction: column;
  align-items: ${p => p.align || 'stretch'};

  &:empty {
    display: none;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;
