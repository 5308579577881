import React, { FC } from 'react';
import styled from 'styled-components';
import { getEnrollmentsModule, useDependentEnrollments } from '~/enrollments';
import { withModules } from '~/shared/withModules';
import { Box, Cell, FlexSpacer, Grid, Theme, PageCenter, Spinner } from '~/ui';
import { Status } from './Status';
import { Year } from './Year';
import { YearDescription } from './YearDescription';

const StyledGrid = styled(Grid)`
  & + & {
    margin-top: ${Theme.Gap};
  }
`;

interface Props {
  dependentId: string;
}

export const DependentEnrollments: FC<Props> = withModules(getEnrollmentsModule(), ({ dependentId }) => {
  const enrollments = useDependentEnrollments(parseInt(dependentId));
  return <Box padding="sm">
    <h3>Enrollment Status</h3>
    {!enrollments.length && <PageCenter><Spinner size="medium" /></PageCenter>}
    {enrollments.map(e => <StyledGrid cols={1} gap="small" key={e.id}>
      <Cell>
        <Year year={e.entryYear} />
        <FlexSpacer />
        <YearDescription year={e.entryYear} />
      </Cell>
      <Cell>
        <Status enrollment={e} dependentId={dependentId} selectedYear={e.entryYear} />
      </Cell>
    </StyledGrid>)}
  </Box>;
});
