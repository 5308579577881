import { IModule } from 'redux-dynamic-modules';
import { reduceReducers } from '~/shared/reduceReducers';
import { deleteDocumentReducer } from './actions/deleteDocument';
import { listDocuments, listDocumentsReducer } from './actions/listDocuments';
import { uploadDocumentReducer } from './actions/uploadDocument';
import { State } from './state';

export function getDocumentsModule(): IModule<State> {
  return {
    id: 'documents',
    reducerMap: {
      documents: reduceReducers(
        uploadDocumentReducer,
        listDocumentsReducer,
        deleteDocumentReducer
      )
    },
    initialActions: [
      listDocuments() as any
    ]
  };
}
