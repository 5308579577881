import { Reducer } from 'redux';
import { ADD_NOTIFICATION, DEL_NOTIFICATION, NOTIFICATION_VIEWED } from './constants';
import { AppNotification } from './State';

export const reducer: Reducer<AppNotification[]> = (state = [], action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [
        ...state,
        {
          id: action.id,
          timeStamp: action.timeStamp,
          title: action.title,
          message: action.message,
          reason: action.reason,
          severity: action.severity
        }
      ];

    case DEL_NOTIFICATION:
      return state.filter(n => n.id !== action.id);

    case NOTIFICATION_VIEWED:
      return state.map(n => {
        if (n.id !== action.id) return n;

        return {
          ...n,
          viewed: true
        };
      });

    default:
      return state;
  }
};
