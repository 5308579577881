import { History } from 'history';
import { Dispatch, Reducer } from 'redux';
import { post } from '~/shared/http';
import { Dependent, DependentsState } from '../state';
import { deserializeDependent, serializeDependent } from './helpers';
import { notify } from '~/notifications/actions';

const ADD_DEPENDENT_REQUEST = 'dependents/ADD_DEPENDENT_REQUEST';
const ADD_DEPENDENT_SUCCESS = 'dependents/ADD_DEPENDENT_SUCCESS';
const ADD_DEPENDENT_FAILURE = 'dependents/ADD_DEPENDENT_FAILURE';

const addDependentRequest = (dependent: Dependent) => ({
  type: ADD_DEPENDENT_REQUEST,
  dependent
});

const addDependentSuccess = (dependent: Dependent) => ({
  type: ADD_DEPENDENT_SUCCESS,
  dependent
});

const addDependentError = () => ({
  type: ADD_DEPENDENT_FAILURE
});

export function addDependent(dependent: Dependent, history: History) {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(addDependentRequest(dependent));
    const response = await post('/api/am/dependents', serializeDependent(dependent));
    const body = await response.json();
    if (response.status === 201) {
      const saved = deserializeDependent(body);
      await dispatch(addDependentSuccess(saved));
      history.push(`/family/dependents/${saved.id}`);
      await dispatch(notify(
        'New family member',
        `${dependent.firstName} was added successfully`,
        ADD_DEPENDENT_SUCCESS,
        'success'));
    }
    else {
      await dispatch(addDependentError());
      await dispatch(notify(
        'New family member',
        `Error adding ${dependent.firstName}: ${body.message || body}`,
        ADD_DEPENDENT_FAILURE,
        'error'));
    }
  };
}

export const addDependentReducer: Reducer<DependentsState> = (state = {}, action) => {
  switch (action.type) {
    case ADD_DEPENDENT_REQUEST:
      return {
        ...state,
        creating: true
      };
    case ADD_DEPENDENT_SUCCESS:
      return {
        ...state,
        data: (state.data || []).concat(action.dependent),
        creating: false
      };
    case ADD_DEPENDENT_FAILURE:
      return {
        ...state,
        creating: false
      };
    default:
      return state;
  }
};
