import { connect, getIn } from 'formik';
import React, { useState } from 'react';
import { FormField, DivFlex } from './Field.styles';

export const Field = connect<any>(({ formik, ...props }) => {
  const error = getIn(formik.errors, props.name);
  const touch = getIn(formik.touched, props.name);
  const [showReset, setShowReset] = useState(false);

  const handleChange = (event: any) => {
    formik.handleChange(event);
    if (event.target.value && event.target.value.length > 0) {
      setShowReset(true);
    } else {
      setShowReset(false);
    }
  };

  const clearField = (event: any) => {
    formik.setFieldValue(props.name, '');
    setShowReset(false);
  };

  return (
    <DivFlex>
      <FormField
        {...props}
        onChange={handleChange}
        invalid={error && touch ? 'true' : ''}
      />
      {props.showclearbutton && showReset && (
        <button onClick={clearField} type="button">
          X
        </button>
      )}
    </DivFlex>
  );
});
