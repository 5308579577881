import React, { FC, ReactNode } from 'react';
import { useAuth } from '~/auth';
import { useDistrictFromName } from '~/customers';
import { getDependentsModule } from '~/dependents';
import { useDependent } from '~/dependents/hooks';
import { withModules } from '~/shared/withModules';
import { Layout, NotFound, Page, PageCenter, Spinner } from '~/ui';
import { Header } from '../Header';
import { Nav } from '../Nav';
import { AddEnrollment } from './AddEnrollment';

interface Props {
  shortName: string;
}

/**
 * Wrapper component handling the flow of creating a new enrollment
 */
export const EnrollDependent: FC<Props> =  withModules([getDependentsModule()], ({ shortName }) => {
  const auth = useAuth();
  const [district, districtStatus] = useDistrictFromName(shortName);
  const params = new URLSearchParams(location.search);
  const dependentId = params.get('id');
  const dependent = useDependent(dependentId);
  const selectedYear = params.get('year');
  let content: ReactNode;

  switch (districtStatus) {
    case 'done':
      if ( dependentId && ! dependent) {
        break;
      }
      content = <AddEnrollment district={district} dependent={dependent} guardianEmail={auth.userInfo.email} selectedYear={selectedYear}/>;
      break;
    case 'notFound':
      content = <NotFound />;
      break;
    case 'error':
      content = <h1>Error</h1>;
      break;
    case 'loading':
      content = <PageCenter><Spinner size="large" /></PageCenter>;
      break;
  }

  return <Layout
    header={<Header />}
    sideBar={auth.authenticated ? <Nav /> : null}
  >
    <Page>{content}</Page>
  </Layout>;
});
