import { Dispatch, Reducer } from 'redux';
import { State } from '~/dependents/state';
import { get } from '~/shared/http';
import { Enrollment, EnrollmentsState } from '../state';
import { deserializeEnrollment, enrollmentYears } from './helpers';

export const LIST_ENROLLMENTS_REQUEST = 'LIST_ENROLLMENTS_REQUEST';
const LIST_ENROLLMENTS_SUCCESS = 'LIST_ENROLLMENTS_SUCCESS';
const LIST_ENROLLMENTS_FAILURE = 'LIST_ENROLLMENTS_FAILURE';

const request = () => ({
  type: LIST_ENROLLMENTS_REQUEST
});

const success = (enrollments: Enrollment[]) => ({
  type: LIST_ENROLLMENTS_SUCCESS,
  enrollments
});

const failure = () => ({
  type: LIST_ENROLLMENTS_FAILURE
});

/**
 * Creates default enrollment records for the current and next school year
 * @param dependentId The id of the dependent
 */
function defaultEnrollments(dependentId: number): Enrollment[] {
  const years = enrollmentYears();
  return [
    {
      id: `**${dependentId}--${years.currentYear.year}`,
      entryYear: years.currentYear.year.toString(),
      dependentId,
      documents: []
    },
    {
      id: `**${dependentId}--${years.nextYear.year}`,
      entryYear: years.nextYear.year.toString(),
      dependentId,
      documents: []
    }
  ];
}

/**
 * Adds default enrollments to a list of enrollments.
 * The defaults are only added if there is no record for the corresponding year
 */
function addDefaults(dependentId: number, enrollments: Enrollment[]) {
  const defaults = defaultEnrollments(dependentId);
  for (let i = 0; i < defaults.length; i++) {
    if (enrollments.find(e => e.entryYear.toString() === defaults[i].entryYear.toString())) continue;
    enrollments.push(defaults[i]);
  }
  return enrollments.sort((a, b) => parseInt(b.entryYear) - parseInt(a.entryYear));
}

export const listEnrollments = () =>
  async (dispatch: Dispatch<any>, getState: () => State) => {
    await dispatch(request());
    try {
      const dependents = getState().dependents.data || [];
      const responses = await Promise.all(dependents.map(async d => {
        const response = await get(`api/enr/api/v2/enrollments?dependent=${d.id}`);
        if (response.status !== 200) throw Error('failed');
        const data = await response.json();
        return addDefaults(d.id, (data || []).map(deserializeEnrollment));
      }));

      let enrollments: Enrollment[] = [];
      for (let i = 0; i < responses.length; i++) {
        enrollments = enrollments.concat(responses[i]);
      }

      await dispatch(success(enrollments));
    }
    catch (e) {
      await dispatch(failure());
    }
  };

export const listEnrollmentsReducer: Reducer<EnrollmentsState> = (state = [], action) => {
  switch (action.type) {
    case LIST_ENROLLMENTS_SUCCESS:
      return action.enrollments;
    default:
      return state;
  }
};
