import { Dispatch, Reducer } from 'redux';
import { notify } from '~/notifications/actions';
import { post } from '~/shared/http';
import { AuthState } from '../State';

const RESET_PASSWORD_REQUEST = 'auth/RESET_PASSWORD_REQUEST';
const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE';

const resetPasswordRequest = () => ({
  type: RESET_PASSWORD_REQUEST
});

const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS
});

const resetPasswordError = () => ({
  type: RESET_PASSWORD_FAILURE
});

export function resetPassword(token: string, password: string) {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(resetPasswordRequest());
    const response = await post(`/api/am/password-reset/${token}`, {
      newPassword: password,
      confirmNewPassword: password
    });

    if (response.status === 200) {
      await dispatch(resetPasswordSuccess());
      return;
    }

    await dispatch(resetPasswordError());
    await dispatch(notify(
      'Password reset',
      'Unexpected error',
      RESET_PASSWORD_FAILURE,
      'error'));
  };
}

export const resetPasswordReducer: Reducer<AuthState> = (state, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPassword: true
      };
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: false
      };
    default:
      return state;
  }
};
