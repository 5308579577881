import styled from 'styled-components';
import { Theme } from '../Theme';

const widthTable = {
  small: '300px',
  medium: '400px',
  large: '600px',
  auto: 'auto'
};

const paddingTable = {
  sm: '20px',
  md: '40px'
};

type BoxWidth = 'small' | 'medium' | 'large' | 'auto';

type BoxPadding = 'sm' | 'md';

interface Props {
  width?: BoxWidth;
  padding?: BoxPadding;
}

export const Box = styled.div<Props>`
  background: #fff;
  border: solid 1px ${Theme.Background};
  padding: ${p => paddingTable[p.padding || 'md']};
  box-sizing: content-box;
  box-shadow: 0 0 8px 0 ${Theme.BoxShadow};
  width: ${p => widthTable[p.width || 'auto']};

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;
