import * as Yup from 'yup';

export class RegistrationModel {
  firstName = '';
  lastName = '';
  email = '';
  password = '';
  confirmPassword = '';

  readonly validationSchema = Yup.object().shape({
    firstName: Yup.string().required('required'),
    lastName: Yup.string().required('required'),
    email: Yup.string().email().required('required'),
    password: Yup.string().password().required('required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'must match password').required('required')
  });

  serialize() {
    return {
      FirstName: this.firstName,
      LastName: this.lastName,
      Email: this.email,
      Password: this.password,
      ConfirmPassword: this.confirmPassword
    };
  }
}
