import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import _ from 'lodash';

Yup.addMethod(Yup.string, 'isDate', function() {
  // tslint:disable-next-line: only-arrow-functions
  return this.test('isDate', 'Should be a valid date', function(value: string) {
    return !value || moment(value).isValid();
  });
});

Yup.addMethod(Yup.string, 'dateBefore', function(date: Moment) {
  return this.test(
    'dateBefore',
    `Should be before ${date.format('l')}`,
    // tslint:disable-next-line: only-arrow-functions
    function(value: string) {
      if (!value) return true;
      const m = moment(value);
      return m.isValid() && m.isBefore(moment(date));
    }
  );
});

Yup.addMethod(Yup.string, 'dateAfter', function(date: Moment) {
  return this.test(
    'dateAfter',
    `Should be after ${date.format('l')}`,
    // tslint:disable-next-line: only-arrow-functions
    function(value: string) {
      if (!value) return true;
      const m = moment(value);
      return m.isValid() && m.isAfter(moment(date));
    }
  );
});

Yup.addMethod(Yup.array, 'unique', function() {
  return this.test(
    'unique',
    'Should have unique values',
    // tslint:disable-next-line: only-arrow-functions
    function(value: any[]) {
      return !value || (_.uniq(value).length === value.length);
    }
  );
});

Yup.addMethod(Yup.string, 'password', function() {
  return this.test(
    'pwd-rules',
    'must be at least 6 characters long and contain at least two of the following: a lowercase, uppercase, number and special character',
    value => {
      if (!value || !value.length) return true;

      let constraintCount = 0;
      if (/[A-Z]/.test(value)) constraintCount++;
      if (/[a-z]/.test(value)) constraintCount++;
      if (/\d/.test(value)) constraintCount++;
      if (/\W/.test(value)) constraintCount++;

      return constraintCount >= 2 && value.length >= 6;
    }
  );
});
