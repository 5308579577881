import styled from 'styled-components';
import { Theme } from '../Theme';

export const Input = styled.input`
  border: solid 1px ${Theme.Background};
  padding: 10px;
  border-radius: 3px;
  height: 50px;
  transition: box-shadow 0.3s ease-in-out;

  &:focus {
    outline: none;
    box-shadow: 0 0 7px ${Theme.Accent};
  }
`;
