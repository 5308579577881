import { connect } from 'formik';
import React from 'react';
import styled from 'styled-components';
import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';

const Errors = connect(({ formik, ...props }) => {
  const errors = Object
    .keys(formik.errors || {})
    .filter(k => (formik.touched as any)[k]);

  if (errors.length === 0) return <></>;

  return <div {...props}>
    Please address the following issues:
    <ul>
      {errors.map(e => <li key={e}>{upperFirst(lowerCase(e))}: {(formik.errors as any)[e]}</li>)}
    </ul>
  </div>;
});

export const ValidationErrors = styled(Errors)`
  font-size: 0.8rem;
  color: darkred;
  margin: 10px 0;
  padding: 10px;
  border: solid 1px darkred;
  border-radius: 4px;
  background: #fff7f7;

  & p {
    margin-bottom: 20px;
  }

  & b {
    font-weight: 500;
  }
`;
