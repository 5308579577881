import { Dispatch, Reducer } from 'redux';
import { notify } from '~/notifications/actions';
import { get } from '~/shared/http';
import { Document, DocumentsState } from '../state';

const LIST_DOCUMENTS_REQUEST = 'LIST_DOCUMENTS_REQUEST';
const LIST_DOCUMENTS_SUCCESS = 'LIST_DOCUMENTS_SUCCESS';
const LIST_DOCUMENTS_FAILURE = 'LIST_DOCUMENTS_FAILURE';

const listDocumentsRequest = () => ({
  type: LIST_DOCUMENTS_REQUEST
});

const listDocumentsSuccess = (documents: Document[]) => ({
  type: LIST_DOCUMENTS_SUCCESS,
  documents
});

const listDocumentsError = () => ({
  type: LIST_DOCUMENTS_FAILURE
});

export function listDocuments() {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(listDocumentsRequest());
    const response = await get('/api/doc/documents');
    const body = await response.json();
    if (response.status === 200) {
      await dispatch(listDocumentsSuccess(body));
    }
    else {
      await dispatch(listDocumentsError());
      await dispatch(notify(
        'Retrieve documents',
        `Error retrieving documents: ${body.message || body}`,
        LIST_DOCUMENTS_FAILURE,
        'error'));
    }
  };
}

export const listDocumentsReducer: Reducer<DocumentsState> = (state = {}, action) => {
  switch (action.type) {
    case LIST_DOCUMENTS_REQUEST:
      return {
        ...state,
        reading: true
      };
    case LIST_DOCUMENTS_SUCCESS:
      return {
        ...state,
        data: action.documents,
        reading: false
      };
    case LIST_DOCUMENTS_FAILURE:
      return {
        ...state,
        reading: false
      };
    default:
      return state;
  }
};
