import { useSelector } from 'react-redux';
import { State } from './state';

export function useDependents() {
  return useSelector((state: State) => state.dependents);
}

export function useDependent(id: string) {
  return useSelector((state: State) => state.dependents.data && state.dependents.data.find(d => d.id.toString() === id));
}
