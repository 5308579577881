import { IModule } from 'redux-dynamic-modules';
import { State } from './state';
import { loadingReducer, errorReducer } from './reducers';

export function getApiStatusModule(): IModule<State> {
  return {
    id: 'apiStatus',
    reducerMap: {
      loadingStatus: loadingReducer,
      apiErrors: errorReducer
    }
  };
}
