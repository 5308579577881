import { Form, Formik, FormikProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { saveUserInfo, useAuth } from '~/auth';
import { countries } from '~/shared/countries';
import { localRules } from '~/shared/localRules';
import { useOnChange } from '~/shared/hooks/useOnChange';
import { Button, Cell, Field, FlexSpacer, Grid, MaskedField, PageWithHeader, VerticalCell } from '~/ui';
import { useApiLoading } from '~/apiStatus';
import { SAVE_USER_INFO_REQUEST } from '~/auth/actions/saveUserInfo';

const StyledForm = styled(Form)`
  padding: 20px;
`;

const UserAccountForm: FunctionComponent<FormikProps<any>> = ({ values, resetForm, dirty, setFieldValue }) => {
  const userInfo = useAuth().userInfo;
  const updating = useApiLoading(SAVE_USER_INFO_REQUEST);

  // Empty zip code when the country changes
  useOnChange(() => setFieldValue('zip', ''), [values.country]);

  const disableButtons = !dirty || updating;

  return <StyledForm>
    <Grid cols={6}>
      <VerticalCell cols={3}>
        <label>First name</label>
        <Field type="text" name="firstName" />
      </VerticalCell>
      <VerticalCell cols={3}>
        <label>Middle name</label>
        <Field type="text" name="middleName" />
      </VerticalCell>
      <VerticalCell cols={3}>
        <label>Last name</label>
        <Field type="text" name="lastName" />
      </VerticalCell>
      <VerticalCell cols={3}>
        <label>Date of birth</label>
        <Field type="date" name="birthDate" />
      </VerticalCell>
      <VerticalCell cols={3}>
        <label>Phone number</label>
        <Field name="phoneNumber" render={({ field }: any) => <MaskedField
          mask={values.country && localRules[values.country].phoneMask}
          {...field} />
        } />
      </VerticalCell>
      <VerticalCell cols={3}>
        <label></label>
      </VerticalCell>
      <VerticalCell cols={3}>
        <label>Country</label>
        <Field component="select" name="country" >
          {!values.country && <option />}
          {countries.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
        </Field>
      </VerticalCell>
      <VerticalCell cols={6}>
        <label>Address</label>
        <Field type="text" name="address" />
      </VerticalCell>
      <VerticalCell cols={4}>
        <label>City</label>
        <Field type="text" name="city" />
      </VerticalCell>
      <VerticalCell cols={3}>
        <label>{values.country === 'USA' ? 'State' : 'Province'}</label>
        <Field component="select" name="state" disabled={!values.country} >
          {!values.state && <option />}
          {values.country && countries.find(c => c.id === values.country).states.map(
            s => <option key={s.id} value={s.id}>{s.name}</option>
          )}
        </Field>
      </VerticalCell>
      <VerticalCell cols={2}>
        <label>{values.country === 'USA' ? 'ZIP' : 'Postal code'}</label>
        <Field name="zip" render={({ field }: any) => <MaskedField
          mask={values.country && localRules[values.country].zipMask}
          {...field} />
        } />
      </VerticalCell>
      <Cell cols={6}>
        <FlexSpacer />
        <Button type="button" onClick={() => resetForm()} disabled={disableButtons}>Cancel</Button>
        <Button kind="primary" type="submit" disabled={disableButtons}>Update</Button>
      </Cell>
    </Grid>
  </StyledForm>;
};

export const UserAccountDetails = () => {
  const dispatch = useDispatch();
  const userInfo = useAuth().userInfo;

  return <PageWithHeader
    header={<h1>My Account</h1>}
  >
    <Formik
      initialValues={userInfo}
      onSubmit={values => dispatch(saveUserInfo(values))}
      component={UserAccountForm} />
  </PageWithHeader>;
};
