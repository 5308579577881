import { useEffect, useState } from 'react';
import { get } from '~/shared/http';
import { District } from './District';
import { SimpleCustomer } from './SimpleCustomer';

export function useSimpleCustomers() {
  const [customers, setCustomers] = useState<SimpleCustomer[]>();
  useEffect(() => {
    get(`/api/enr/api/v3/customers`)
      .then(r => r.json())
      .then((body: SimpleCustomer[]) => {
        body.sort((a, b) => a.name.localeCompare(b.name, navigator.language));
        setCustomers(body);
      });
  }, []);

  return customers;
}

export type DistrictStatus = 'loading' | 'done' | 'notFound' | 'error';

/**
 * Retrieves a customer's (district) enrollment configuration from its short name
 *
 * @param name The customer's short name
 */
export function useDistrictFromName(name: string) {
  const [district, setDistrict] = useState<District>();
  const [status, setStatus] = useState<DistrictStatus>('loading');
  useEffect(() => {
    if (!name) return;
    get(`/api/enr/api/v3/customers/byname/${name}`)
      .then(async r => {
        switch (r.status) {
          case 200:
            const data: District = await r.json();
            setChoices(data, 'city');
            setChoices(data, 'gradelevel');
            setChoices(data, 'zip');
            createMasks(data);
            setDistrict(data);
            setStatus('done');
            break;
          case 404:
            setStatus('notFound');
            break;
          default:
            setStatus('error');
            break;
        }
      });
  }, [name]);

  return [district, status] as [District, DistrictStatus];
}

/**
 * Retrieves a customer's config based on its id.
 * I've not typed the result because it's so complex...
 *
 * @param id The customer's id
 */
export function useDistrictFromId(id: number | string) {
  const [district, setDistrict] = useState();
  const [status, setStatus] = useState<DistrictStatus>('loading');

  useEffect(() => {
    if (!id) return;
    get(`/api/enr/api/v2/customers/${id}/config`)
      .then(async r => {
        switch (r.status) {
          case 200:
            const data: District = await r.json();
            setDistrict(data);
            setStatus('done');
            break;
          case 404:
            setStatus('notFound');
            break;
          default:
            setStatus('error');
            break;
        }
      });
  }, [id]);

  return [district, status] as [any, DistrictStatus];
}

/**
 * Parses and sorts the list of choices coming from the back-end
 */
function setChoices(district: District, key: string) {
  const field = district.fields.find(f => f.key === key);
  if (!field) return;
  field.$choices = (field.values || [])
    .map(v => {
      const [name, id] = v.split('|');
      return { id: id || name, name };
    })
    .sort((a, b) => {
      const intA = parseInt(a.id);
      const intB = parseInt(b.id);
      if (isNaN(intA) || isNaN(intB)) {
        return a.id.localeCompare(b.id);
      }
      return intA - intB;
    });
}

const maskTable: { [key: string]: string | RegExp } = {
  0: /\d/,
  A: /[A-Z]/
};

function createMasks(district: District) {
  district.fields.forEach(f => {
    if (!f.validation) return;
    f.$mask = f.validation.split('').map(v => maskTable[v] || v);
  });
}
