import { Dispatch, Reducer } from 'redux';
import { post } from '~/shared/http';
import { notify } from '~/notifications/actions';
import { AuthState } from '../State';

const ACTIVATE_REQUEST = 'auth/ACTIVATE_REQUEST';
const ACTIVATE_SUCCESS = 'auth/ACTIVATE_SUCCESS';
const ACTIVATE_FAILURE = 'auth/ACTIVATE_FAILURE';

const activateRequest = () => ({
  type: ACTIVATE_REQUEST
});

const activateSuccess = () => ({
  type: ACTIVATE_SUCCESS
});

const activateError = () => ({
  type: ACTIVATE_FAILURE
});

export function activate(token: string) {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(activateRequest());
    const response = await post('/api/am/account/activate', { token });
    if (response.status === 200) {
      await dispatch(activateSuccess());
      return;
    }

    await dispatch(activateError());
    await dispatch(notify(
      'Account activation',
      response.status === 404 ? 'Invalid token' : 'Unexpected error',
      ACTIVATE_FAILURE,
      'error'));
  };
}

export const activateReducer: Reducer<AuthState> = (state, action) => {
  switch (action.type) {
    case ACTIVATE_REQUEST:
      return {
        ...state,
        activating: true
      };
    case ACTIVATE_SUCCESS:
    case ACTIVATE_FAILURE:
      return {
        ...state,
        activating: false
      };
    default:
      return state;
  }
};
