import moment from 'moment';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { DocumentSelector } from './DocumentSelector';
import { Document } from '~/documents/state';

const ButtonBar = styled.div`
  display: flex;
  align-self: baseline;
`;

const List = styled.div`
  max-height: 250px;
  overflow-y: auto;
  position: relative;
`;

const ListHead = styled.div`
  display: grid;
  grid-template-columns: auto 130px;
  width: 100%;
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: solid 1px var(--grey-bg);
  font-weight: bold;

  & > div {
    padding: 10px;
  }
`;

const ListContent = styled.div`
  display: grid;
  grid-template-columns: auto 130px;
  width: 100%;

  & > div {
    padding: 10px;
  }
`;
const FlexColumn = styled.div`
  flex-direction:row;
 }
 `;

interface LiProp {
  document: Document;
}

const ListItem: React.FunctionComponent<LiProp> = ({ document }) => <>
  <div><label htmlFor={`cb-${document.guid}`}>{document.name}</label></div>
  <div>{moment(document.uploadDate).format('MM/DD/YYYY')}</div>
</>;

interface Props {
  enrollmentDocuments: Document[];
  setEnrollmentDocuments: (documents: Document[]) => void;
}

export const EnrollmentDocumentList: FC<Props> = ({ enrollmentDocuments, setEnrollmentDocuments }) => {
  const hasDocuments = enrollmentDocuments && enrollmentDocuments.length > 0;
  return <FlexColumn>
    <ButtonBar>
      <DocumentSelector enrollmentDocuments={enrollmentDocuments} setEnrollmentDocuments={setEnrollmentDocuments} />
    </ButtonBar>
    {!hasDocuments || enrollmentDocuments.length === 0 && <p>
      You haven't selected any document yet.
    </p>}
    {enrollmentDocuments.length > 0 && <List>
      <ListHead>
        <div>Name</div>
        <div>Uploaded</div>
      </ListHead>
      <ListContent>
        {hasDocuments && enrollmentDocuments.map(d => <ListItem key={d.guid} document={d} />)}
      </ListContent>
    </List>}
  </FlexColumn>;

};
