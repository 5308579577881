import styled from 'styled-components';
import { Theme } from '~/ui';
import { Severity } from '../State';

const colorTable = {
  info: 'blue',
  warning: 'orange',
  error: 'red',
  success: 'green'
};

export const Container = styled.div`
  border: solid 1px ${Theme.LightBackground};
  width: 270px;
  box-shadow: 0 0 20px -5px ${Theme.Grey};
  border-radius: 4px;
  background: #fff;
  display: grid;
  grid-template-columns: 50px auto;
  overflow: hidden;
  pointer-events: auto;
`;

interface PaneProps {
  severity: Severity;
}

export const Pane = styled.div<PaneProps> `
  padding: 10px 0 10px 10px;
  svg {
    color: ${p => colorTable[p.severity]}
  }
`;

export const Header = styled.div`
  padding: 10px 12px 5px 8px;
  align-items: flex-start;
  display: flex;

  svg {
    margin-right: 5px;
  }
`;

export const Content = styled.div`
  padding: 5px 8px 10px;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
`;
