import { faFile, faInbox, faUserGraduate, faUserPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { getDependentsModule, useDependents } from '~/dependents';
import { withModules } from '~/shared/withModules';
import { Menu, MenuItem } from '~/ui';
import { MenuLink } from '~/ui/components/MenuLink';
import { Dependent } from '~/dependents/state';

export const Nav = withModules([getDependentsModule()], () => {
  const dependents = useDependents();
  return <Menu>
    <MenuLink icon={faInbox} label="Inbox" to="/family" exact />
    <MenuItem icon={faUsers} label="Family">
      <Menu>
        {dependents.data && dependents.data.sort((a, b) => {
          const firstNameA = a.firstName ? a.firstName.toLowerCase() : '';
          const firstNameB = b.firstName ? b.firstName.toLowerCase() : '';
          // asc order
          if (firstNameA < firstNameB) {
            return -1;
          } else if (firstNameA > firstNameB) {
            return 1;
          } else {
            return 0;
          }
        }).map(d =>
          <MenuLink key={d.id} icon={faUserGraduate} to={`/family/dependents/${d.id}`} label={d.firstName} />
        )}
        <MenuLink icon={faUserPlus} to="/family/dependents/new" label="Add member" />
      </Menu>
    </MenuItem>
    <MenuLink icon={faFile} to="/family/documents" label="Documents" />
  </Menu>;
});
