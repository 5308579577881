import MaskedInput from 'react-text-mask';
import styled, { css } from 'styled-components';
import { Theme } from '../Theme';

export const MaskedField = styled(MaskedInput)`
  border: solid 1px ${Theme.Background};
  padding: 10px;
  border-radius: 3px;
  height: 50px;
  transition: box-shadow ease-in-out .3s;

  ${(p: any) => p.invalid && css`
    color: darkred;
    border-color: darkred;
  `}

  &:focus {
    outline: none;
    box-shadow: 0 0 7px ${Theme.Accent};
  }
`;
