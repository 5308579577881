import { faCheckCircle, faExclamationCircle, faInfoCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { FlexSpacer } from '~/ui';
import { deleteNotification } from '../actions';
import { AppNotification } from '../State';
import { CloseButton, Container, Content, Header, Pane } from './NotificationBox.styles';

const icons = {
  info: faInfoCircle,
  warning: faExclamationCircle,
  error: faTimesCircle,
  success: faCheckCircle
};

interface Props {
  notification: AppNotification;
}

/**
 * Renders a single noification
 */
export const NotificationBox: FC<Props> = ({ notification }) => {
  const dispatch = useDispatch();

  return <Container>
    <Pane severity={notification.severity}>
      <FontAwesomeIcon size="2x" icon={icons[notification.severity]} />
    </Pane>
    <div>
      <Header>
        <b>{notification.title}</b>
        <FlexSpacer />
        <CloseButton onClick={() => dispatch(deleteNotification(notification.id))}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
      </Header>
      <Content>{notification.message.map((m, i) => <p key={i}>{m}</p>)}</Content>
    </div>
  </Container>;
};
