import { Enrollment } from '../state';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import moment from 'moment';
import { Documents } from '~/parentPortal/components/Documents';

export const deserializeEnrollment = ({
  _id,
  customerId,
  dependentId,
  last_name,
  first_name,
  middle_name,
  student_number,
  home_phone,
  guid,
  dob,
  grade_level,
  entry_date,
  email,
  username,
  password,
  accessid,
  accesspwd,
  parentaccess,
  street,
  city,
  state,
  zip,
  sibling,
  entryyear,
  schoolPreference,
  pendingSchools,
  rejectedSchools,
  approvedSchool,
  when_created,
  entry_date_unix,
  pendingSchoolsCount,
  parentContacts,
  approval,
  documents
}: any) => ({
  id: _id || '',
  customerId: customerId || '',
  dependentId: dependentId || '',
  lastName: last_name || '',
  firstName: first_name || '',
  middleName: middle_name || '',
  studentNumber: student_number || '',
  homePhone: home_phone || '',
  guid: guid || '',
  birthDate: formatDate(dob),
  gradeLevel: grade_level || '',
  entryDate: formatDate(entry_date),
  email: email || '',
  userName: username || '',
  password: password || '',
  accessId: accessid || '',
  accessPassword: accesspwd || '',
  parentAccess: parentaccess || '',
  street: street || '',
  city: city || '',
  state: state || '',
  zip: zip || '',
  sibling: sibling || false,
  entryYear: entryyear || '',
  schoolPreference: schoolPreference || [],
  pendingSchools: pendingSchools || [],
  rejectedSchools: rejectedSchools || [],
  approvedSchool: approvedSchool || '',
  whenCreated: when_created,
  unixEntryDate: entry_date_unix,
  pendingSchoolsCount,
  parentContacts,
  approval: false,
  documents: documents
} as Enrollment);

export const serializeEnrollment = (e: Enrollment) => omitBy({
  _id: e.id,
  customerId: e.customerId,
  dependentId: e.dependentId,
  last_name: e.lastName,
  first_name: e.firstName,
  middle_name: e.middleName,
  student_number: e.studentNumber,
  home_phone: e.homePhone,
  guid: e.guid,
  dob: e.birthDate,
  grade_level: e.gradeLevel,
  entry_date: e.entryDate,
  email: e.email,
  username: e.userName,
  password: e.password,
  accessid: e.accessId,
  accesspwd: e.accessPassword,
  parentaccess: e.parentAccess,
  street: e.street,
  city: e.city,
  state: e.state,
  zip: e.zip,
  sibling: e.sibling,
  entryyear: e.entryYear,
  schoolPreference: e.schoolPreference,
  pendingSchools: e.pendingSchools,
  rejectedSchools: e.rejectedSchools,
  approvedSchool: e.approvedSchool,
  when_created: e.whenCreated,
  entry_date_unix: e.unixEntryDate,
  pendingSchoolsCount: e.pendingSchoolsCount,
  approval: e.approval,
  documents: e.documents
}, p => isEmpty(p) && !isNumber(p));

function formatDate(d: string) {
  if (!d) return '';

  return moment(d).format('YYYY-MM-DD');
}

export const enrollmentYears = () => {
  let year = (new Date()).getFullYear();
  if ((new Date()).getMonth() < 6) year--;
  return {
    currentYear: {
      name: 'Current year',
      year
    },
    nextYear: {
      name: 'Next year',
      year: year + 1
    }
  };
};
