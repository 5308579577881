import { useState } from 'react';
import { post } from '~/shared/http';
import { RegistrationModel } from '../models/RegistrationModel';

type Status = 'requesting' | 'done' | 'error' | 'inUse';

export function useRegister() {
  const [status, setStatus] = useState<Status>();

  async function register(model: RegistrationModel) {
    setStatus('requesting');
    const response = await post('/api/am/create/local', model.serialize());

    switch (response.status) {
      case 201:
        setStatus('done');
        return;
      case 409:
        setStatus('inUse');
        return;
      default:
        setStatus('error');
        return;
    }
  }

  return [status, register] as [Status, typeof register];
}
