import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { InitiatePasswordReset, LoginPage, PrivateRedirect, PrivateRoute, RegistrationPage } from './auth';
import { Activate } from './auth/components/Activate';
import { DoResetPassword } from './auth/components/DoResetPassword';
import { EnrollDependent } from './parentPortal/components/EnrollDependent/EnrollDependent';
import { Routes as ParentRoutes } from './parentPortal/routes';
import { browserHistory } from './shared/browserHistory';
import { useLoginOnly } from './shared/hooks/useLoginOnly';
import { NotFound } from './ui';
import { PasswordResetError } from './auth/components/PasswordResetError';

export const Routes = () => {
  const loginOnly = useLoginOnly();
  return <Router history={browserHistory}>
    {!loginOnly && <Switch>
      <Route path="/login" exact component={LoginPage} />
      <Route path="/reset-password" exact component={InitiatePasswordReset} />
      <Route path="/reset-password/accept" exact component={DoResetPassword} />
      <Route path="/reset-password/bad-link" exact component={PasswordResetError} />
      <Route path="/register" component={RegistrationPage} />
      <Route path="/activate" component={Activate} />
      <PrivateRoute path="/family/enroll/:shortName" exact render={({ match }) => <EnrollDependent shortName={match.params.shortName} />} />
      <PrivateRoute path="/family" component={ParentRoutes} />
      <PrivateRoute path="/:slug" component={NotFound} />
      <PrivateRedirect to="/family" />
    </Switch>}
    {loginOnly && <Switch>
      <Route path="/login" exact component={LoginPage} />
      <Route path="/reset-password" exact component={InitiatePasswordReset} />
      <Route path="/reset-password/accept" exact component={DoResetPassword} />
      <Route path="/reset-password/bad-link" exact component={PasswordResetError} />
      <Redirect to="/login" />
    </Switch>}
  </Router>;
};
