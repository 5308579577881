import React from 'react';
import { PageWithHeader } from '~/ui';

export const Home = () => {
  return <PageWithHeader
    header={<h1>Home</h1>}
  >
    This is a test
  </PageWithHeader>;
};
