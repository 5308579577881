import React, { ComponentType, ReactNode } from 'react';
import { Layout } from '~/ui';
import { Header } from '../components/Header';

export const withLayout = <T extends {}>(sideBar: ReactNode, WrappedComponent: ComponentType<T>) =>
  (props: T) => <Layout
    header={<Header />}
    sideBar={sideBar}
  >
    <WrappedComponent {...props} />
  </Layout>;
