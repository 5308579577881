import { useSelector, useDispatch } from 'react-redux';
import { State } from './state';
import { useEffect } from 'react';
import { clearErrors } from './actions';

/**
 * Returns the status of an action type
 * @param actionType an action type value
 */
export function useApiStatus(actionType: string) {
  const state = useSelector((s: State) => s.loadingStatus);
  const [, name] = /^(.*)_\w+$/.exec(actionType);
  return state[name];
}

/**
 * Checks whether at least one of the provided actions is currently awaiting
 * an API response.
 * @param actionTypes a list of action type values
 */
export function useApiLoading(...actionTypes: string[]) {
  const state = useSelector((s: State) => s.loadingStatus);

  for (let i = 0; i < actionTypes.length; i++) {
    const [, name] = /^(.*)_\w+$/.exec(actionTypes[i]);
    if (state[name]) return true;
  }

  return false;
}

/**
 * Checks if at least one of the provided actions has resulted in an error.
 * @param actionTypes a list of action type values
 */
export function useApiFailure(...actionTypes: string[]) {
  const state = useSelector((s: State) => s.apiErrors);

  return actionTypes
    .map(a => {
      const [, name] = /^(.*)_\w+$/.exec(a);
      return state[name];
    })
    .filter(e => e);
}

export function useClearErrors() {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(clearErrors()); }, []);
}
