import { useState } from 'react';
import { post } from '~/shared/http';

type Status = 'requesting' | 'done' | 'invalidEmail' | 'error';

export function useInitiatePasswordReset() {
  const [status, setStatus] = useState<Status>();

  async function requestEmail(email: string) {
    setStatus('requesting');
    const response = await post('/api/am/password-reset/init', { email });

    switch (response.status) {
      case 200:
        setStatus('done');
        return;
      case 404:
        setStatus('invalidEmail');
        return;
      default:
        setStatus('error');
        return;
    }
  }

  return [status, requestEmail] as [Status, typeof requestEmail];
}
