import { Formik } from 'formik';
import React, { FC, useState } from 'react';
import { District } from '~/customers/District';
import { Button, Cell, FlexSpacer, Form, Grid, ValidationErrors, VerticalCell } from '~/ui';
import { AddEnrollmentModel, validationSchema } from '../models/AddEnrollmentModel';
import { DistrictLogo } from './DistrictLogo';
import { EditContact } from './EditContact';
import { EnrollmentDetails } from './EnrollmentDetails';
import { HouseholdInformation } from './HouseholdInformation';
import { SchoolPreference } from './SchoolPreference';
import { StudentInformation } from './StudentInformation';
import ReCAPTCHA from 'react-google-recaptcha';
import { EnrollmentDocuments } from './EnrollmentDocuments';

let siteKey: string;
if (process.env.API_ENV === 'production') {
  siteKey = '6LdNwBMUAAAAAFdusXZFd8Fz3ZJQfFssy-6uYKey';
}
else if (process.env.API_ENV === 'staging') {
  siteKey = '6LfJKIUUAAAAAA1wOmbZnyMU7Mph3yypONM8YulO';
}
else if (process.env.API_ENV === 'development_cloud') {
  siteKey = '6LfxQqAUAAAAAKRwP0njm3eV6roKrxzPwtjH2oWr';
}
else {
  siteKey = '6Le_10YUAAAAAKAcxncvykvwCy90iV1VEP0n9ZgG';
}

interface Props {
  district: District;
  initialValues: AddEnrollmentModel;
  showCaptcha: boolean;
  submit: (enrollment: AddEnrollmentModel) => void;
}

export const EnrollmentForm: FC<Props> = ({ district, initialValues, submit, showCaptcha }) =>
  <Formik
    initialValues={initialValues}
    onSubmit={submit}
    validationSchema={validationSchema(district, showCaptcha)}
  >
    {({ values, setFieldValue }) =>
      <Form>
        <Grid cols={6}>
          <DistrictLogo district={district} />
          <VerticalCell cols={6}>
            <ValidationErrors />
          </VerticalCell>
          <StudentInformation />
          <SchoolPreference district={district} schoolPreference={values.schoolPreference} />
          <HouseholdInformation district={district} />
          {showParent(district, 'contact1') && <EditContact index="1" district={district} />}
          {showParent(district, 'contact2') && <EditContact index="2" district={district} />}
          <EnrollmentDetails setFieldValue={setFieldValue} district={district} values={values} />
          <EnrollmentDocuments enrollmentDocuments={values.documents} setEnrollmentDocuments={documents => setFieldValue('documents', documents)}  />
          <Cell cols={6}>
            {showCaptcha && <ReCAPTCHA
              sitekey={siteKey}
              onChange={value => setFieldValue('recaptchaResponse', value)}
              onExpired={() => setFieldValue('recaptchaResponse', '')}
            />}
            <FlexSpacer />
            <Button kind="primary" type="submit">Submit</Button>
          </Cell>
        </Grid>
      </Form>
    }
  </Formik >;

/**
 * Determines whether the fields for a given contact should be visible based on
 * district settings.
 */
function showParent(district: District, field: 'contact1' | 'contact2') {
  const parentContacts = district.fields.find(f => f.key === 'parentContacts') || {} as any;
  return parentContacts[field] && parentContacts[field].enabled;
}
