import { withLayout } from '~/parentPortal/hoc/withLayout';
import { PageCenter, Box } from '~/ui';
import React from 'react';
import { Link } from 'react-router-dom';

export const PasswordResetError = withLayout(null, () =>
  <PageCenter>
    <Box width="medium">
      <h1>Password reset</h1>
      <p>
        The link you have used to reset your password is either expired or invalid.
      </p>
      <p>
        Click <Link to="/reset-password">here</Link> to get a new link emailed to you.
      </p>
    </Box>
  </PageCenter>
);
