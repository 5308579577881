import styled from 'styled-components';
import { Theme } from '../Theme';
export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 30px;
  z-index: 1;
`;
export const Main = styled.div`
  border: solid 1px ${Theme.Grey};
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
`;
export const Header = styled.div`
  padding: 20px;
  display: flex;
`;
export const Footer = styled.div`
  padding: 20px;
  display: flex;
`;

export const Content = styled.div`
  padding: 0 20px;
`;
