import React, { FunctionComponent, ReactNode } from 'react';
import { Wrapper, Header, Content } from './PageWithHeader.styles';

export const PageWithHeader: FunctionComponent<{
  header: ReactNode
}> = ({ header, children }) => <Wrapper>
  <Header>{header}</Header>
  <Content>
    {children}
  </Content>
</Wrapper>;
