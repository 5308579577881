import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export const PrivateRoute: FunctionComponent<RouteProps> = ({ component, render, ...rest }) => {
  const auth = useAuth();

  if (auth.authenticated && render) {
    return <Route {...rest} render={render} />;
  }

  return <Route {...rest} render={props => auth.authenticated ?
    React.createElement(component, props)
    :
    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  } />;
};
