import { Dependent } from '../state';
import moment from 'moment';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

const parseDOB = ( DOB: any )  => {
  let returnDate = '';
  if ( DOB ) {
    // DOB on an existing dependent is a string formatted as "1950-01-01T06:00:00.000Z" - so remove the timezone part to turn it into a wall clock date
    if ( DOB.indexOf('T') > -1 ) {
      returnDate = moment(DOB.substr(0, DOB.indexOf('T'), 'YYYY-MM-DD' )).format('YYYY-MM-DD');
    } else {
      // when a dependent is first saved, the response return DOB as 'YYYY-MM-DD' but here DOB could also be '' or invalid
      const db = moment( DOB, 'YYYY-MM-DD' );
      if (db.isValid()){
        returnDate = db.format('YYYY-MM-DD');
      }
    }
  }
  return returnDate;
};

export const deserializeDependent = ({
  ID,
  GUID,
  FirstName,
  MiddleName,
  LastName,
  DOB,
  HomePhone,
  Street,
  Country,
  State,
  City,
  Zip,
  Ethnicity,
  Gender
}: any) => ({
  id: ID,
  guid: GUID,
  firstName: FirstName || '',
  middleName: MiddleName || '',
  lastName: LastName || '',
  birthDate: parseDOB( DOB ),
  phoneNumber: HomePhone || '',
  address: Street || '',
  city: City || '',
  state: State || '',
  zip: Zip || '',
  country: Country || 'USA',
  ethnicity: Ethnicity || '',
  gender: Gender || ''
} as Dependent);

export const serializeDependent = (d: Dependent) => (omitBy({
  ID: d.id,
  GUID: d.guid,
  FirstName: d.firstName,
  MiddleName: d.middleName,
  LastName: d.lastName,
  DOB: d.birthDate,
  HomePhone: d.phoneNumber,
  Street: d.address,
  Country: d.country,
  State: d.state,
  City: d.city,
  Zip: d.zip,
  Ethnicity: d.ethnicity,
  Gender: d.gender
}, p => isEmpty(p) && !isNumber(p)));
