import { useState, useRef, FC } from 'react';
import React from 'react';
import { Button, Modal, Cell, FlexSpacer, Flex, VerticalCell } from '~/ui';
import { useDispatch } from 'react-redux';
import { uploadDocument } from '~/documents';
import { HiddenFile, FileName } from './DocumentUpload.styles';
import styled from 'styled-components';

const UploadButton = styled(Button)`
  color: tomato;
  border-color: tomato;
`;

interface Props {
  acceptedFileTypes?: string; /* comma separate list like .jpg, .gif*/
  maxFileSize?: string; /* human readable limit like '5Mb' or '500kb' */
}

export const DocumentUpload: FC <Props> = ({ acceptedFileTypes, maxFileSize }) => {
  const [showModal, setShowModal] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');
  const dispatch = useDispatch();

  function openModal() {
    setShowModal(true);
    setFileName('');
  }

  function fileSelected() {
    setFileName(fileInput.current.value.replace(/^.*[\/\\](.*)$/, '$1'));
  }

  function upload() {
    dispatch(uploadDocument(fileInput.current.files.item(0)));
    setShowModal(false);
  }
  function getFileFormatString(){
    let fileLimitsString = '';
    if (acceptedFileTypes ){
      fileLimitsString = `Accepted formats are ${acceptedFileTypes}`;
    }
    if ( maxFileSize ) {
      fileLimitsString += ` (Max ${maxFileSize})`;
    }
    return fileLimitsString;
  }

  return <>
    <UploadButton kind="primary" onClick={openModal}>Upload</UploadButton>
    {showModal && <Modal
      header={<h2>File Upload</h2>}
      footer={
        <>
          <FlexSpacer />
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
          <Button kind="primary" disabled={!fileName} onClick={upload}>Upload</Button>
        </>
      }
    >
      <p>By uploading this document, you agree to AccelaSchool's <a href="https://accelaschool.com/policies/terms" target="_blank">terms of use</a>.</p>
      <HiddenFile type="file" ref={fileInput} accept={acceptedFileTypes} onChange={fileSelected} />
      <VerticalCell align="flex-start">
        <Button kind="primary" onClick={() => fileInput.current.click()}>Choose file</Button>
        <p>{ getFileFormatString() } </p>
        <FileName>{fileName}</FileName>
      </VerticalCell>
    </Modal>}
  </>;
};
