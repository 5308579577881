import { Dispatch, Reducer } from 'redux';
import { notify } from '~/notifications/actions';
import { del } from '~/shared/http';
import { DocumentsState } from '../state';

const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';

const deleteDocumentRequest = (guid: string) => ({
  type: DELETE_DOCUMENT_REQUEST,
  guid
});

const deleteDocumentSuccess = (guid: string) => ({
  type: DELETE_DOCUMENT_SUCCESS,
  guid
});

const deleteDocumentError = () => ({
  type: DELETE_DOCUMENT_FAILURE
});

export function deleteDocument(guid: string) {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(deleteDocumentRequest(guid));
    const response = await del(`/api/doc/documents/doc/${guid}`);
    if (response.status === 200) {
      await dispatch(deleteDocumentSuccess(guid));
      await dispatch(notify(
        'Delete document',
        `Document deleted successfully`,
        DELETE_DOCUMENT_SUCCESS,
        'success'));
    }
    else {
      const body = await response.json();
      await dispatch(deleteDocumentError());
      await dispatch(notify(
        'Delete document',
        `Error deleting the document: ${body.message || body}`,
        DELETE_DOCUMENT_FAILURE,
        'error'));
    }
  };
}

export const deleteDocumentReducer: Reducer<DocumentsState> = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        data: state.data.filter(d => (d.guid !== action.guid)),
        deleting: false
      };
    case DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        deleting: false
      };
    default:
      return state;
  }
};
