import { createGlobalStyle } from 'styled-components';
import styledSanitize from 'styled-sanitize';
import { Theme } from '../Theme';

export const GlobalStyle = createGlobalStyle`
  ${styledSanitize}

  @import url('https://fonts.googleapis.com/css?family=Heebo:300,400,500');

  html {
    background: #fff;
    font-family: 'Heebo', sans-serif;
    font-size: 14pt;
    font-weight: 300;
    color: ${Theme.DarkGrey};
  }

  html, body, div#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  h1 {
    font-size: 24pt;
    font-weight: 400;
  }

  h2 {
    font-size: 20pt;
    font-weight: 500;
  }

  h3 {
    font-size: 16pt;
    font-weight: 400;
  }

  h4 {
    font-size: 14pt;
    font-weight: 500;
  }

  table {
    width: 100%;
    border: solid 1px ${Theme.DarkGrey};
  }

  td, th {
    padding: 15px;
    text-align: left;
  }

  th {
    background: ${Theme.DarkGrey};
    color: ${Theme.LightBackground};
  }

  tr:nth-child(2n) {
    background: ${Theme.LightBackground};
  }

  a, a:visited {
    color: ${Theme.Accent};
    text-decoration: none;
  }

  button:focus {
    outline: none;
  }

  .popup-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .popup-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
    transform-origin: top center;
  }
  .popup-exit {
    opacity: 1;
  }
  .popup-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
    transform-origin: top center;
  }

  input[type=checkbox] + label {
    margin-left: ${Theme.SmallGap};
  }
`;
