export let ValidateUrls: {
  cmp: string,
  am: string,
  doc: string,
  enr: string
};

if (process.env.API_ENV === 'production') {
  ValidateUrls = {
    cmp: 'http%3A%2F%2Fcmp.accelaschool.com',
    am: 'http%3A%2F%2Faccounts.accelaschool.com',
    doc: 'http%3A%2F%2Fdocuments.accelaschool.com',
    enr: 'http%3A%2F%2Fecollect.accelaschool.com'
  };
}
else if (process.env.API_ENV === 'staging') {
  ValidateUrls = {
    cmp: 'http%3A%2F%2Fqa2-cmp.accelaschool.com',
    am: 'http%3A%2F%2Fqa2-am.accelaschool.comm',
    doc: 'http%3A%2F%2Fqa2-doc.accelaschool.com',
    enr: 'http%3A%2F%2Fqa2-enr.accelaschool.com'
  };
}
else if (process.env.API_ENV === 'development_cloud') {
  ValidateUrls = {
    cmp: 'http%3A%2F%2Fdev-cmp.accelaschool.com',
    am: 'http%3A%2F%2Fdev-am.accelaschool.com',
    doc: 'http%3A%2F%2Fdev-da.accelaschool.com',
    enr: 'http%3A%2F%2Fdev-enr.accelaschool.com'
  };
}
else {
  ValidateUrls = {
    cmp: 'https%3A%2F%2Flocalhost%3A3000',
    am: 'https%3A%2F%2Flocalhost%3A3500',
    doc: 'https%3A%2F%2Flocalhost%3A5000',
    enr: 'https%3A%2F%2Flocalhost%3A4000'
  };
}
