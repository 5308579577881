import styled from 'styled-components';

interface Props {
  column?: boolean;
}

export const Flex = styled.div<Props>`
  display: flex;
  flex-direction: ${p => p.column ? 'column' : 'row'};
`;
