import React, { FC, useState } from 'react';
import { Enrollment } from '~/enrollments/state';
import { browserHistory } from '~/shared/browserHistory';
import { Button, FlexSpacer, LinkButton } from '~/ui';
import { DistrictSelectionModal } from '../DistrictSelectionModal';
import { useDispatch } from 'react-redux';
import { removeEnrollment } from '~/enrollments/actions/removeEnrollment';
import styled from 'styled-components';

const EditLink = styled(LinkButton)`
  margin-left: 8px;
`;

interface Props {
  enrollment: Enrollment;
  dependentId: string;
  selectedYear: string;
}

export const Status: FC<Props> = ({ enrollment, dependentId, selectedYear }) => {
  const [showSelector, setShowSelector] = useState(false);
  const dispatch = useDispatch();

  if (!enrollment.guid) {
    const selected = (shortName: string) => {
      setShowSelector(false);
      browserHistory.push(`/family/enroll/${shortName}?id=${dependentId}&year=${selectedYear}`);
    };

    return <>
      <span>Not yet enrolled</span>
      <FlexSpacer />
      <Button kind="primary" size="small" onClick={() => setShowSelector(true)}>Enroll</Button>
      {showSelector && <DistrictSelectionModal canceled={() => setShowSelector(false)} selected={selected} />}
    </>;
  }

  if (enrollment.approvedSchool) {
    return <span>Enrolled at {enrollment.approvedSchool}</span>;
  }

  if (enrollment.pendingSchools && enrollment.pendingSchools.length) {
    return <>
      <span>Pending...</span>
      <FlexSpacer />
      <Button size="small" onClick={() => {
        if (!confirm('Permanently withdraw this enrollment request?')) {
          return;
        }
        dispatch(removeEnrollment(enrollment.guid));
      }}>Withdraw</Button>
      <EditLink size="small" to={`/family/dependents/${dependentId}/enrollments/${enrollment.guid}`}>Edit</EditLink>
    </>;
  }

  if (enrollment.rejectedSchools && enrollment.rejectedSchools.length) {
    return <span>Rejected.</span>;
  }

  return <></>;
};
