import styled from 'styled-components';
import { Theme } from '~/ui';

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const Toggle = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  color: #fff;
  border: none;
  background: transparent;
  transition: background ease-in-out .2s;

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  svg {
    margin-left: 10px;
  }
`;

export const Popup = styled.div`
  position: absolute;
  top: calc(100% + 15px);
  right: 0;
  padding: 10px 0;
  background: ${Theme.DarkGrey};
  box-shadow: 0 0 8px 0 rgba(0,0,0,.34);

  :before, :after {
    content: ' ';
    position: absolute;
    bottom: 100%;
    display: block;
    border: solid 8px ${Theme.DarkGrey};
    border-top-color: transparent;
  }

  :before {
    border-left-color: transparent;
    right: 50%;
  }

  :after {
    border-right-color: transparent;
    left: 50%;
  }
`;

export const MenuItem = styled.li`
  cursor: pointer;
  white-space: nowrap;
  transition: background ease-in-out .2s;

  svg {
    margin-right: 10px;
  }

  a {
    display: inline-block;
    padding: 15px 30px;
    word-break: keep-all;
    text-decoration: none;
    color: #fff;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;
