import { UISref } from '@uirouter/react';
import React, { FC } from 'react';
import { EnrollmentDocumentList } from './EnrollmentDocumentList';
import { Document } from '~/documents/state';
import { Cell } from '~/ui/components/Cell';
import { LoginPage, useAuth } from '~/auth';
import { VerticalCell } from '~/ui/components/VerticalCell';

interface Props{
  enrollmentDocuments: Document[];
  setEnrollmentDocuments: ( documents: Document[]) => void;
}
export const EnrollmentDocuments: FC<Props> = ( { enrollmentDocuments, setEnrollmentDocuments }) => {
  const auth = useAuth();

  return <>
   <VerticalCell cols={6}>
      <h2>Documents</h2>
    </VerticalCell>
  {auth.authenticated && <>
    <Cell cols={6}>
      You can include additional documents to your enrollment application.
    </Cell>
    <Cell cols={6}>
      <EnrollmentDocumentList enrollmentDocuments={enrollmentDocuments} setEnrollmentDocuments={setEnrollmentDocuments} />
    </Cell>
  </>}

  {!auth.authenticated && <Cell cols={6}>
    You need to be signed in to attach documents to your enrollment application.<br />
    Click  <UISref to={LoginPage.name}><a>here</a></UISref> to sign in or register.
  </Cell>}
</>;
};
