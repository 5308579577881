import styled from 'styled-components';
import { Theme } from '../Theme';
import { Li } from './MenuLink.styles';

export const Item = styled.div`
  padding: 20px;
  color: ${Theme.DarkGrey};
  text-decoration: none;
  word-break: normal;
  display: flex;

  svg {
    margin-right: 10px;
  }
`;
export const Content = styled.div`
  ${Li} > a {
    padding-left: 45px;
  }
`;
