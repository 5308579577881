import styled from 'styled-components';

export const ErrorPane = styled.div`
  font-size: 0.8rem;
  color: darkred;
  margin: 10px 0;
  padding: 10px;
  border: solid 1px darkred;
  border-radius: 4px;
  background: #fff7f7;

  & p {
    margin-bottom: 20px;
  }

  & p:last-child {
    margin-bottom: 0;
  }

  & b {
    font-weight: 500;
  }
`;
