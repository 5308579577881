import { Dispatch, Reducer } from 'redux';
import { put } from '~/shared/http';
import { UserInfo, AuthState } from '../State';
import { notify } from '~/notifications/actions';

export const SAVE_USER_INFO_REQUEST = 'SAVE_USER_INFO_REQUEST';
const SAVE_USER_INFO_SUCCESS = 'SAVE_USER_INFO_SUCCESS';
const SAVE_USER_INFO_FAILURE = 'SAVE_USER_INFO_FAILURE';

const saveUserInfoRequest = () => ({
  type: SAVE_USER_INFO_REQUEST
});

const saveUserInfoSuccess = (userInfo: UserInfo) => ({
  type: SAVE_USER_INFO_SUCCESS,
  userInfo
});

const saveUserInfoError = (error: string) => ({
  type: SAVE_USER_INFO_FAILURE,
  error
});

export function saveUserInfo(userInfo: UserInfo) {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(saveUserInfoRequest());

    const response = await put('/api/am/userdata', {
      ID: userInfo.id,
      Email: userInfo.email,
      FirstName: userInfo.firstName,
      MiddleName: userInfo.middleName,
      LastName: userInfo.lastName,
      DOB: userInfo.birthDate,
      HomePhone: userInfo.phoneNumber,
      Street: userInfo.address,
      Country: userInfo.country,
      State: userInfo.state,
      City: userInfo.city,
      Zip: userInfo.zip
    });

    if (response.status === 200) {
      await dispatch(saveUserInfoSuccess(userInfo));
      await dispatch(notify('Account Update', 'Your changes were saved successfully', SAVE_USER_INFO_REQUEST, 'success'));
    }
    else {
      const body = await response.json();
      await dispatch(saveUserInfoError(body.message));
    }
  };
}

export const saveUserInfoReducer: Reducer<AuthState> = (state, action) => {
  switch (action.type) {
    case SAVE_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...action.userInfo
        }
      };

    default:
      return state || {};
  }
};
