import React, { FC } from 'react';
import { enrollmentYears } from '~/enrollments';

/**
 * Describes the enrollment year
 */
export const YearDescription: FC<{
  year: string;
}> = ({ year }) => {
  const years = enrollmentYears();
  if (years.currentYear.year.toString() === year.toString()) {
    return <>{years.currentYear.name}</>;
  }
  else if (years.nextYear.year.toString() === year.toString()) {
    return <>{years.nextYear.name}</>;
  }
  return <>{'Past school year'}</>;
};
