import { Field as FormikField } from 'formik';
import styled, { css } from 'styled-components';
import { Theme } from '../Theme';

export const DivFlex = styled.div`
  display: flex;
`;

export const FormField = styled(FormikField)`
  border: solid 1px ${Theme.Background};
  padding: 10px;
  border-radius: 3px;
  height: 50px;
  transition: box-shadow 0.3s ease-in-out;
  flex:1;
  ${p => p.invalid && css`
    color: darkred;
    border-color: darkred;
  `}

  &[type=checkbox] {
    margin-right: 10px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 7px ${Theme.Accent};
  }
  ~ button {
    margin-left: -1px;
    cursor: pointer;
    border: none;
    padding: 15px 10px;
    border-radius: 0px 4px 4px 0px;
    color: #fff;
    background: #c3c3c3;
    margin-bottom: 2px;
    -webkit-transition: background ease-in-out .3s,color ease-in-out .3s,box-shadow ease-in-out .3s;
    transition: background ease-in-out .3s,color ease-in-out .3s,box-shadow ease-in-out .3s;
  }
`;
