import { IModule } from 'redux-dynamic-modules';
import { reduceReducers } from '~/shared/reduceReducers';
import { activateReducer } from './actions/activate';
import { getUserInfo, getUserInfoReducer } from './actions/getUserInfo';
import { loginReducer } from './actions/login';
import { logoutReducer } from './actions/logout';
import { resendEmailReducer } from './actions/resendEmail';
import { resetPasswordReducer } from './actions/resetPassword';
import { saveUserInfoReducer } from './actions/saveUserInfo';
import { State } from './State';

export function getAuthModule(): IModule<State> {
  return {
    id: 'auth',
    reducerMap: {
      auth: reduceReducers(
        getUserInfoReducer,
        loginReducer,
        logoutReducer,
        saveUserInfoReducer,
        activateReducer,
        resetPasswordReducer,
        resendEmailReducer
      )
    },
    initialActions: [
      getUserInfo() as any
    ]
  };
}
