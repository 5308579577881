import { Reducer } from 'redux';
import { ApiStatus, ApiErrors } from './state';
import { REQUEST, SUCCESS, FAILURE, CLEAR_FAILURES } from './constants';

export const loadingReducer: Reducer<ApiStatus> = (state = {}, action) => {
  const matches = /^(.*)_(\w+)$/.exec(action.type);
  if (!matches) return state;

  const [, name, type] = matches;

  switch (type) {
    case REQUEST:
      return {
        ...state,
        [name]: true
      };
    case SUCCESS:
    case FAILURE:
      return {
        ...state,
        [name]: false
      };
    default:
      return state;
  }
};

export const errorReducer: Reducer<ApiErrors> = (state = {}, action) => {
  if (action.type === CLEAR_FAILURES) {
    return {};
  }

  const matches = /^(.*)_(\w+)$/.exec(action.type);
  if (!matches) return state;

  const [, name, type] = matches;

  if (type === FAILURE) {
    return {
      ...state,
      [name]: action.error || 'Unexpected error'
    };
  }

  return state;
};
