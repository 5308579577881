import { IModule } from 'redux-dynamic-modules';
import { State } from './state';
import { getDependentsModule } from '~/dependents';
import { reduceReducers } from '~/shared/reduceReducers';
import { listEnrollmentsReducer, listEnrollments } from './actions/listEnrollments';
import { removeEnrollmentReducer } from './actions/removeEnrollment';

export function getEnrollmentsModule() {
  return [
    getDependentsModule(),
    {
      id: 'enrollments',
      reducerMap: {
        enrollments: reduceReducers(
          listEnrollmentsReducer,
          removeEnrollmentReducer
        )
      },
      initialActions: [
        listEnrollments() as any
      ]
    } as IModule<State>
  ];
}
