import { Dispatch, Reducer } from 'redux';
import { notify } from '~/notifications/actions';
import { get } from '~/shared/http';
import { Dependent, DependentsState } from '../state';
import { deserializeDependent } from './helpers';

export const LIST_DEPENDENTS_REQUEST = 'dependents/LIST_DEPENDENTS_REQUEST';
const LIST_DEPENDENTS_SUCCESS = 'dependents/LIST_DEPENDENTS_SUCCESS';
const LIST_DEPENDENTS_FAILURE = 'dependents/LIST_DEPENDENTS_FAILURE';

const listDependentsRequest = () => ({
  type: LIST_DEPENDENTS_REQUEST
});

const listDependentsSuccess = (dependents: Dependent[]) => ({
  type: LIST_DEPENDENTS_SUCCESS,
  dependents
});

const listDependentError = () => ({
  type: LIST_DEPENDENTS_FAILURE
});

export function listDependents() {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(listDependentsRequest());
    const response = await get('/api/am/dependents');
    const body = await response.json();
    if (response.status === 200) {
      const dependents = body.map(deserializeDependent);
      await dispatch(listDependentsSuccess(dependents));
    }
    else {
      await dispatch(listDependentError());
      await dispatch(notify(
        'Retrieve family member',
        `Error retrieving family members: ${body.message || body}`,
        LIST_DEPENDENTS_FAILURE,
        'error'));
    }
  };
}

export const listDependentsReducer: Reducer<DependentsState> = (state = {}, action) => {
  switch (action.type) {
    case LIST_DEPENDENTS_REQUEST:
      return {
        ...state,
        reading: true
      };
    case LIST_DEPENDENTS_SUCCESS:
      return {
        ...state,
        data: action.dependents,
        reading: false
      };
    case LIST_DEPENDENTS_FAILURE:
      return {
        ...state,
        reading: false
      };
    default:
      return state;
  }
};
