import React, { ChangeEvent, FC, useState } from 'react';
import { SimpleCustomer, useSimpleCustomers } from '~/customers';
import { Button, FlexSpacer, Grid, Modal, VerticalCell, Input } from '~/ui';

interface Props {
  selected: (districtId: string) => void;
  canceled: () => void;
}

export const DistrictSelectionModal: FC<Props> = ({ selected, canceled }) => {
  const customers = useSimpleCustomers();
  const [customer, setCustomer] = useState<SimpleCustomer>(null);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const match = (customers || []).find(c => c.name === e.target.value);
    if (match) setCustomer(match);
  };

  return <Modal
    header={<h2>Choose a school district</h2>}
    footer={<>
      <FlexSpacer />
      <Button onClick={canceled}>Cancel</Button>
      <Button kind="primary" disabled={!customer} onClick={() => selected(customer.shortName)}>Continue</Button>
    </>}
  >
    <Grid>
      <VerticalCell>
        <Input type="text" name="code" onChange={changeHandler} placeholder="Find district" list="district-list" />
        <datalist id="district-list">
          {customers && customers.map((c: any) => <option key={c.shortName} value={c.name} />)}
        </datalist>
      </VerticalCell>
    </Grid>
  </Modal>;
};
