import { Dispatch, Reducer } from 'redux';
import { get } from '~/shared/http';
import { AuthState } from '../State';

const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

const logoutRequest = () => ({
  type: LOGOUT_REQUEST
});

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

const logoutError = (error: string) => ({
  type: LOGOUT_FAILURE,
  error
});

export function logout() {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(logoutRequest());
    const response = await get('/api/am/auth/logout');
    if (response.status === 200) {
      sessionStorage.removeItem('authToken');
      await dispatch(logoutSuccess());
    }
    else {
      const body = await response.json();
      await dispatch(logoutError(body.message));
    }
  };
}

export const logoutReducer: Reducer<AuthState> = (state, action) => {
  switch (action.type) {
    case LOGOUT_REQUEST:
      return {
        ...state,
        logout: {
          inProgress: true
        }
      };
    case LOGOUT_SUCCESS:
      return {
        authenticated: false,
        initialized: true,
      };
    case LOGOUT_FAILURE:
      return {
        authenticated: false,
        initialized: true,
        logout: {
          error: action.error
        }
      };
    default:
      return state || {};
  }
};
