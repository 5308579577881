import React, { FC } from 'react';
import { Wrapper, Main, Header, Footer, Content } from './Modal.styles';

interface Props {
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

export const Modal: FC<Props> = props => <Wrapper>
  <Main>
    {props.header && <Header>{props.header}</Header>}
    <Content>{props.children}</Content>
    {props.footer && <Footer>{props.footer}</Footer>}
  </Main>
</Wrapper>;
