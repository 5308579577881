import styled, { css } from 'styled-components';
import { Theme } from '../Theme';
import { Link } from 'react-router-dom';

function color(kind: string) {
  switch (kind) {
    case 'primary':
      return Theme.Primary;
    case 'accent':
      return Theme.Accent;
    default:
      return Theme.Grey;
  }
}

interface Props {
  kind?: 'primary' | 'default' | 'accent';
  size?: 'regular' | 'small';
}

const generic = css<Props>`
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  color: #fff;
  background: ${p => color(p.kind)};
  margin-bottom: 2px;
  transition: background ease-in-out .3s, color ease-in-out .3s, box-shadow ease-in-out .3s;
  font-weight: 400;

  &:visited {
    color: #fff;
  }

  & + & {
    margin-left: 10px;
  }

  &:disabled {
    background: ${Theme.Background};
    color: ${Theme.LightBackground};
  }

  &:active {
    transform: translateY(2px);
    outline: none;
    box-shadow: 0 0 1px 0 ${Theme.Grey};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 7px ${p => color(p.kind)};
  }

  ${p => p.size === 'small' && css`
    font-size: 0.8rem;
    padding: 5px 10px;
    height: auto;
  `}
`;

export const Button = styled.button`${generic}`;
export const LinkButton = styled(Link)`${generic}`;
