import styled from 'styled-components';
import { Theme } from '../Theme';

interface WrapperProps {
  withSidebar: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: grid;
  grid-template-rows: 60px auto 35px;
  grid-template-columns: ${p => p.withSidebar ? '250px auto' : '1fr'};
  width: 100%;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  grid-column: span 2;
  background: ${Theme.DarkGrey};
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #fff;
`;

export const SideBarContainer = styled.div`
  padding: 10px 0;
  background: ${Theme.LightBackground};
  overflow: auto;
`;

export const Footer = styled.div`
  background: ${Theme.DarkGrey};
  padding: 0 20px;
  display: flex;
  color: #fff;
  grid-row: 3;
  grid-column: span 2;
  align-items: center;
  font-size: 0.7rem;
  color: ${Theme.LightBackground};
  * + * {
    margin-left: 10px;
  }
`;
