import React, { FC } from 'react';
/**
 * Formats the enrollment year
 */
export const Year: FC<{
  year: string;
}> = ({ year }) => {
  const n = parseInt(year);
  return <b>{`${year}-${n + 1}`}</b>;
};
