import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { withLayout } from '~/parentPortal/hoc/withLayout';
import { useLoginOnly } from '~/shared/hooks/useLoginOnly';
import { Box, Button, Cell, ErrorPane, Field, FlexSpacer, Grid, ValidationErrors, VerticalCell, PageCenter } from '~/ui';
import { useInitiatePasswordReset } from '../hooks/useInitiatePasswordReset';

export const InitiatePasswordReset = withLayout(null, () => {
  const [status, request] = useInitiatePasswordReset();
  const loginOnly = useLoginOnly();

  let content: ReactNode;
  if (status === 'done') {
    content = <p>
      Thank you. An e-mail has been sent with instructions on how to reset your password.
    </p>;
  }
  else {
    content = <>
      <p>
        If you have forgotten your password, enter your e-mail below and we'll send you a message with instructions
        on how to reset it.
      </p>
      {status === 'invalidEmail' && <ErrorPane>
        The email provided was not found. {loginOnly && <>You can use it to <Link to="/register">register</Link></>}
      </ErrorPane>}
      {status === 'error' && <ErrorPane>
        An unexpected error has occurred.
      </ErrorPane>}
      <Formik
        initialValues={{ email: '' }}
        onSubmit={({ email }) => request(email)}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required('Required')
        })}
        validateOnBlur={false}
      >
        {() => <Form>
          <Grid>
            <VerticalCell>
              <ValidationErrors />
            </VerticalCell>
            <VerticalCell>
              <Field type="email" name="email" placeholder="Email" />
            </VerticalCell>
            <Cell>
              <FlexSpacer />
              <Button type="submit" kind="primary" disabled={status === 'requesting'}>Reset password</Button>
            </Cell>
          </Grid>
        </Form>}
      </Formik>
    </>;
  }

  return <PageCenter>
    <Box width="medium">
      <h1>Password reset</h1>
      {content}
    </Box>
  </PageCenter>;
});
