import styled from 'styled-components';
import { Theme } from '../Theme';
import { NavLink } from 'react-router-dom';

export const Li = styled.li`
  padding: 12px;
`;

export const StyledNavLink = styled(NavLink)`
  && {
    border-radius: 5px;
    padding: 8px;
    display: flex;
    color: ${Theme.DarkGrey};
    text-decoration: none;
    word-break: normal;
    transition: background ease-in-out .2s;
  }

  &&:hover {
    background-color: ${Theme.LightAccent};
  }

  &&.active {
    background: ${Theme.Accent};
    font-weight: 500;
  }

  svg {
    margin-right: 10px;
  }
`;
