type HttpInterceptor = (response: Response) => Promise<Response>;

const interceptors: HttpInterceptor[] = [];

export function addInterceptor(interceptor: HttpInterceptor) {
  interceptors.push(interceptor);
}

export const get = (url: string) => makeRequest(url, {
  method: 'GET'
});

export function post(url: string, data: any) {
  let headers: HeadersInit = {};
  if (!(data instanceof FormData)) {
    headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };
  }

  return makeRequest(url, {
    method: 'POST',
    headers,
    body: data instanceof FormData ? data : JSON.stringify(data)
  }).then(response => {
    return response;
  });
}

export const put = (url: string, data: any) => makeRequest(url, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  },
  body: JSON.stringify(data)
});

export const del = (url: string) => makeRequest(url, {
  method: 'DELETE'
});

async function makeRequest(url: string, options: RequestInit) {
  const token = sessionStorage.getItem('authToken');
  if (process.env.API_ENV === 'development_cloud' && token) {
    // Only use bearer token in the development_cloud environment because that's where we serve the UI branches through Netlify
    // and we cannot rely on cookie auth.
    // In other environments, we use cookie auth to avoid login loops when users log out of CMP or Enrollment. The login
    // loops will occur in development_cloud and that's all there is to it...
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`
    };
  }
  let response = await fetch(url, options);

  for (let i = 0; i < interceptors.length; i++) {
    let r = await interceptors[i](response);
    response = r || response;
  }

  return response;
}
