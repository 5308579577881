import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ServiceUrls } from '~/shared/ServiceUrls';
import { Button, Cell, Field, FlexSpacer, Grid, VerticalCell } from '~/ui';
import { useSimpleCustomers } from '~/customers/hooks';

const P = styled.p`
  font-size: 0.8rem;
`;

export const SchoolSelection = () => {
  const customers = useSimpleCustomers();
  const [selection, setSelection] = useState();

  function submit() {
    window.location.href = `${ServiceUrls.enr}/${selection}`;
  }

  return <Formik
    initialValues={{
      code: ''
    }}
    onSubmit={({ code }) => { }}
    validationSchema={Yup.object().shape({
      code: Yup.string().required('Required')
    })}
    validateOnBlur={false}
  >
    {({ values }) => {

      const customer = (customers || []).find((c: any) => c.name === values.code);
      if (customer) {
        setSelection(customer.shortName);
      }

      return <Form>
        <Grid>
          <Cell>
            <h1>Select a school</h1>
          </Cell>
          <VerticalCell>
            <P>
              Use the search box below to find and select your school district,
              then click on the Go button to continue.
            </P>
          </VerticalCell>
          <VerticalCell>
            <Field type="text" name="code" placeholder="Find district" list="district-list" showclearbutton="true" />
            {(values.code.length > 1) && <datalist id="district-list">
              {customers && customers.map((c: any) => <option key={c.shortName} value={c.name} />)}
            </datalist>}
          </VerticalCell>
          <Cell>
            <FlexSpacer />
            <Button type="submit" kind="primary" disabled={!selection} onClick={submit}>Go</Button>
          </Cell>
        </Grid>
      </Form>;
    }}
  </Formik>;
};
