import React, { FC } from 'react';
import { useApiLoading } from '../../../apiStatus';
import { useDistrictFromId, useDistrictFromName } from '../../../customers';
import { EnrollmentForm, getEnrollmentsModule, LIST_ENROLLMENTS_REQUEST, useDependentEnrollment, useUpdateEnrollment } from '../../../enrollments';
import { getDocumentsModule, useDocuments } from '../../../documents';
import { AddEnrollmentModel } from '../../../enrollments/models/AddEnrollmentModel';
import { withModules } from '../../../shared/withModules';
import { NotFound, Page, PageCenter, Spinner, PageWithHeader } from '../../../ui';
import { District } from '../../../customers/District';
import { Document } from '../../../documents/state';

interface Props {
  id: string;
  guid: string;
}

export const EditEnrollment: FC<Props> = withModules([getEnrollmentsModule(), getDocumentsModule()], ({ id, guid }) => {
  const enrollment = useDependentEnrollment(id, guid);
  const loading = useApiLoading(LIST_ENROLLMENTS_REQUEST);
  const [customer, customerStatus] = useDistrictFromId(enrollment && enrollment.customerId);
  const [district, districtStatus] = useDistrictFromName(customer && customer.public.shortname);
  const [updateEnrollment, status] = useUpdateEnrollment();
  const documents = useDocuments();

  if (loading || customerStatus === 'loading' || districtStatus === 'loading') {
    return <PageCenter>
      <Spinner size="large" />
    </PageCenter>;
  }

  if (!enrollment) {
    return <NotFound />;
  }
  // documents in the enrollment are only the GUID so need to map to data from other API
  let docInfo: Document[] = [];
  if (documents && documents.data && enrollment.documents && enrollment.documents.length > 0) {
    docInfo = documents.data.filter(doc => enrollment.documents.find(d => d.guid === doc.guid));
  }

  const initialValues = new AddEnrollmentModel({
    guid: enrollment.guid,
    dependentId: enrollment.dependentId,
    firstName: enrollment.firstName,
    middleName: enrollment.middleName,
    lastName: enrollment.lastName,
    gradeLevel: enrollment.gradeLevel,
    homePhone: enrollment.homePhone,
    birthDate: enrollment.birthDate,
    entryDate: enrollment.entryDate,
    entryYear: enrollment.entryYear,
    email: enrollment.email,
    street: enrollment.street,
    city: enrollment.city,
    state: enrollment.state,
    zip: enrollment.zip,
    sibling: enrollment.sibling,
    schoolPreference: enrollment.schoolPreference,
    parent1Name: enrollment.parentContacts && enrollment.parentContacts.contact1 && enrollment.parentContacts.contact1.name,
    parent1Phone: enrollment.parentContacts && enrollment.parentContacts.contact1 && enrollment.parentContacts.contact1.phone,
    parent2Name: enrollment.parentContacts && enrollment.parentContacts.contact2 && enrollment.parentContacts.contact2.name,
    parent2Phone: enrollment.parentContacts && enrollment.parentContacts.contact2 && enrollment.parentContacts.contact2.phone,
    documents: docInfo,
  });

  const formProps = {
    district,
    initialValues,
    showCaptcha: false,
    submit: (e: AddEnrollmentModel) => updateEnrollment(e)
  };

  return <PageWithHeader
    header={<h1>Editing enrollment for {enrollment.firstName}</h1>}
  >
    {status === 'updating' && <Spinner size="large" />}
    <EnrollmentForm {...formProps} />
  </PageWithHeader>;
});
