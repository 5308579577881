import { FC, ReactNode } from 'react';
import React from 'react';
import { Wrapper, HeaderContainer, SideBarContainer, Footer } from './Layout.styles';
import moment from 'moment';
import { FlexSpacer } from './FlexSpacer';

interface Props {
  header: ReactNode;
  sideBar: ReactNode;
}

export const Layout: FC<Props> = ({ header, sideBar, children }) =>
  <Wrapper withSidebar={!!sideBar}>
    <HeaderContainer>{header}</HeaderContainer>
    {sideBar && <SideBarContainer>{sideBar}</SideBarContainer>}
    {children}
    <Footer>
      <span>&copy; PowerSchool </span>
      <a href="https://www.powerschool.com/privacy/" target="_blank">Privacy</a>
      <b>&middot;</b>
      <a href="https://www.powerschool.com/terms/" target="_blank">Terms</a>
      <FlexSpacer />
      <a href="https://www.powerschool.com">www.powerschool.com</a>
    </Footer>
  </Wrapper>;
