import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useApiFailure, useApiStatus, useClearErrors } from '~/apiStatus';
import { Button, Cell, ErrorPane, Field, FlexSpacer, Grid, ValidationErrors, VerticalCell } from '~/ui';
import { login, LOGIN_REQUEST } from '../actions/login';
import { useLoginOnly } from '~/shared/hooks/useLoginOnly';

export const LoginForm = () => {
  const dispatch = useDispatch();
  useClearErrors();
  const errors = useApiFailure(LOGIN_REQUEST);
  const inProgress = useApiStatus(LOGIN_REQUEST);
  const loginOnly = useLoginOnly();

  return <Formik
    initialValues={{
      email: '',
      password: ''
    }}
    onSubmit={({ email, password }) => dispatch(login(email, password))}
    validationSchema={Yup.object().shape({
      email: Yup.string().email().required('Required'),
      password: Yup.string().required('Required')
    })}
    validateOnBlur={false}
  >
    {() => <Form>
      <Grid>
        <Cell>
          <h1>Sign In</h1>
        </Cell>
        {!loginOnly && <span>
          Don't have an account yet? <Link to="/register">click here to sign up</Link>.
        </span>}
        <VerticalCell>
          <ValidationErrors />
        </VerticalCell>
        {errors.length > 0 && <VerticalCell>
          <ErrorPane>
            {errors.map(e => <p key={e}>{e}</p>)}
          </ErrorPane>
        </VerticalCell>}
        <VerticalCell>
          <Field type="email" name="email" placeholder="Email" />
        </VerticalCell>
        <VerticalCell>
          <Field type="password" name="password" placeholder="Password" />
        </VerticalCell>
        <Cell>
          <Link to="/reset-password">Forgot password?</Link>
          <FlexSpacer />
          <Button type="submit" kind="primary" disabled={inProgress}>Sign In</Button>
        </Cell>
      </Grid>
    </Form>}
  </Formik>;
};
