import React from 'react';
import styled from 'styled-components';
import { useNotifications } from '../hooks';
import { NotificationBox } from './NotificationBox';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Container = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  z-index: 100;
  pointer-events: none;

  .notification-enter {
    opacity: 0;
    transform: translateY(30px);
  }
  .notification-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .notification-exit {
    opacity: 1;
  }
  .notification-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`;

export const NotificationsHost = () => {
  const notifications = useNotifications();

  return <TransitionGroup component={Container}>
    {notifications.map(n => <CSSTransition key={n.id} timeout={300} classNames="notification">
      <NotificationBox notification={n} />
    </CSSTransition>)}
  </TransitionGroup>;
};
