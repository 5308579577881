import styled from 'styled-components';
import { Theme } from '~/ui';

export const Wrapper = styled.div`
  padding: 40px;
`;

export const Row = styled.tr`
  td:nth-child(1) svg {
    margin-right: 10px;
  }

  td:nth-child(2) {
    width: 130px;
  }

  td:nth-child(3) {
    width: 110px;
  }

  a {
    margin-left: 20px;
    cursor: pointer;
    color: ${Theme.Accent};
  }

  th {
    font-weight: 500;
  }
`;

export const MessageTd = styled.td`
  padding: 30px;
  text-align: center;
`;

export const Table = styled.table`
  box-shadow: 0 0 8px 0 rgba(0,0,0,.34);
`;
