import { Dispatch, Reducer } from 'redux';
import { notify } from '~/notifications/actions';
import { del } from '~/shared/http';
import { Dependent, DependentsState } from '../state';

const DELETE_DEPENDENT_REQUEST = 'dependents/DELETE_DEPENDENT_REQUEST';
const DELETE_DEPENDENT_SUCCESS = 'dependents/DELETE_DEPENDENT_SUCCESS';
const DELETE_DEPENDENT_FAILURE = 'dependents/DELETE_DEPENDENT_FAILURE';

const deleteDependentRequest = (id: number) => ({
  type: DELETE_DEPENDENT_REQUEST,
  id
});

const deleteDependentSuccess = (id: number) => ({
  type: DELETE_DEPENDENT_SUCCESS,
  id
});

const deleteDependentError = () => ({
  type: DELETE_DEPENDENT_FAILURE
});

export function deleteDependent(dependent: Dependent) {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(deleteDependentRequest(dependent.id));
    const response = await del(`/api/am/dependents/${dependent.id}`);
    if (response.status === 200) {
      await dispatch(deleteDependentSuccess(dependent.id));
      await dispatch(notify(
        'Remove family member',
        `${dependent.firstName} was removed successfully`,
        DELETE_DEPENDENT_SUCCESS,
        'success'));
    }
    else {
      const body = await response.json();
      await dispatch(deleteDependentError());
      await dispatch(notify(
        'Remove family member',
        `Error removing ${dependent.firstName}: ${body.message || body}`,
        DELETE_DEPENDENT_FAILURE,
        'error'));
    }
  };
}

export const deleteDependentReducer: Reducer<DependentsState> = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DEPENDENT_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case DELETE_DEPENDENT_SUCCESS:
      return {
        ...state,
        data: state.data.filter(d => (d.id !== action.id)),
        deleting: false
      };
    case DELETE_DEPENDENT_FAILURE:
      return {
        ...state,
        deleting: false
      };
    default:
      return state;
  }
};
