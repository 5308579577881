import React, { FC } from 'react';
import { VerticalCell, Field, MaskedField } from '~/ui';
import { District } from '~/customers/District';

interface Props {
  district: District;
}

export const HouseholdInformation: FC<Props> = ({ district }) => {
  const cityField = district.fields.find(f => f.key === 'city');
  const phoneField = district.fields.find(f => f.key === 'homephone');
  const zipField = district.fields.find(f => f.key === 'zip');
  const cities = cityField && cityField.$choices;

  // NOTE: the district's label for zipcodes is still 'zip' when the country is CA, hence the manual adjustment to the label below
  // NOTE: The input mask is different than the validation rules.  CMP sends input masks that are enforced in the validation rules so the input mask
  //       cannot be different than validation rule or submission will fail
  return <>
    <VerticalCell cols={6}>
      <h2>Household Information</h2>
    </VerticalCell>
    <VerticalCell cols={3}>
      <label>Phone Number</label>
      {phoneField.$mask && <Field name="homePhone" render={({ field }: any) =>
        <MaskedField mask={phoneField.$mask} guide {...field} />
      } />}
      {!phoneField.$mask && <Field name="homePhone" />}
    </VerticalCell>

    <VerticalCell cols={3}>
      <label>Guardian E-mail</label>
      <Field name="email" />
    </VerticalCell>

    <VerticalCell cols={6}>
      <label>Address</label>
      <Field name="street" />
    </VerticalCell>

    <VerticalCell cols={3}>
      <label>City</label>
      {cities.length === 0 &&
        <Field name="city" />}
      {cities.length > 0 &&
        <Field name="city" component="select">
          {cities.map(c => <option key={c.id} value={c.id}>
            {c.name}
          </option>)}
        </Field>
      }
    </VerticalCell>

    <VerticalCell cols={3}>
      <label>{district.nationalization === 'US' ? 'ZIP' : 'Postal code'}</label>
      {zipField.$mask && <Field name="zip" render={({ field }: any) =>
        <MaskedField mask={zipField.$mask} guide {...field} />
      } />}
      {!zipField.$mask && <Field name="zip" />}
    </VerticalCell>
  </>;
};
