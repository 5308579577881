import * as React from 'react';
import styled from 'styled-components';
import { UserMenu } from './UserMenu';
import { FlexSpacer } from '~/ui';
import { Link } from 'react-router-dom';

const Image = styled.img`
  height: 30px;
`;

export const Header: React.FunctionComponent = () =>
  <>
    <Link to="/">
      <Image src="images/logo_unified-administration-enrollment_express-inverse.png" />
    </Link>
    <FlexSpacer />
    <UserMenu />
  </>;
