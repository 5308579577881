import { Dispatch, Reducer } from 'redux';
import { post } from '~/shared/http';
import { getUserInfo } from './getUserInfo';
import { AuthState } from '../State';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';

const loginRequest = () => ({
  type: LOGIN_REQUEST
});

const loginSuccess = (token: string) => ({
  type: LOGIN_SUCCESS,
  token
});

const loginError = (error: string) => ({
  type: LOGIN_FAILURE,
  error
});

export function login(email: string, password: string) {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(loginRequest());
    const response = await post('/api/am/auth/local', { email, password });
    if (response.status === 200) {
      const content = await response.json();
      sessionStorage.setItem('authToken', content.token);
      await dispatch(loginSuccess(content.token));
      await dispatch(getUserInfo());
    }
    else {
      const body = await response.json();
      await dispatch(loginError(body.message));
    }
  };
}

export const loginReducer: Reducer<AuthState> = (state, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        authenticated: true,
        notActivated: false
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        authenticated: false
      };
    default:
      return state || {};
  }
};
