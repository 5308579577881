import { Dispatch, Reducer } from 'redux';
import { notify } from '~/notifications/actions';
import { post } from '~/shared/http';
import { DocumentsState } from '../state';
import { listDocuments } from './listDocuments';

const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
const UPLOAD_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';

const uploadDocumentRequest = () => ({
  type: UPLOAD_DOCUMENT_REQUEST
});

const uploadDocumentSuccess = () => ({
  type: UPLOAD_DOCUMENT_SUCCESS
});

const uploadDocumentError = () => ({
  type: UPLOAD_DOCUMENT_FAILURE
});

export function uploadDocument(file: File) {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(uploadDocumentRequest());
    const formData = new FormData();
    formData.append('userDoc', file);
    const response = await post('/api/doc/documents/new', formData);
    let error: string;
    switch (response.status) {
      case 201:
        await dispatch(uploadDocumentSuccess());
        await dispatch(listDocuments());
        await dispatch(notify(
          'File upload',
          `The file was uploaded successfully`,
          UPLOAD_DOCUMENT_SUCCESS,
          'success'));
        return;
      case 413:
        error = 'File too big';
        break;
      case 400:
        error = 'This file type is not accepted';
        break;
      default:
        error = 'Unexpected error';
        break;
    }

    await dispatch(notify(
      'File upload',
      error,
      UPLOAD_DOCUMENT_FAILURE,
      'error'));
  };
}

export const uploadDocumentReducer: Reducer<DocumentsState> = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        creating: true
      };
    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        creating: false
      };
    case UPLOAD_DOCUMENT_FAILURE:
      return {
        ...state,
        creating: false
      };
    default:
      return state;
  }
};
