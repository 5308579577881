import { useState } from 'react';
import { post } from '~/shared/http';

type Status = 'requesting' | 'done' | 'error';

export function useResetPassword() {
  const [status, setStatus] = useState<Status>();

  async function resetPassword(token: string, password: string) {
    setStatus('requesting');
    const response = await post(`/api/am/password-reset/${token}`, {
      newPassword: password,
      confirmNewPassword: password
    });

    switch (response.status) {
      case 200:
        setStatus('done');
        return;
      default:
        setStatus('error');
        return;
    }
  }

  return [status, resetPassword] as [Status, typeof resetPassword];
}
