import { useEffect, useRef } from 'react';

export function useDocumentClick(effect: () => void, enabled: boolean) {
  const clickRef = useRef<() => void>();

  useEffect(() => {
    if (enabled) {
      clickRef.current = () => effect();
      document.addEventListener('click', clickRef.current);
    }

    return () => {
      if (clickRef.current) {
        document.removeEventListener('click', clickRef.current);
        clickRef.current = null;
      }
    };
  }, [enabled]);
}
