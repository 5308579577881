import React, { FC } from 'react';
import { Field, VerticalCell, MaskedField } from '~/ui';
import { District, convertDistrictCountryCodeToDependentCountry } from '~/customers/District';
import { localRules } from '~/shared/localRules';

interface Props {
  index: string;
  district: District;
}

export const EditContact: FC<Props> = ({ index, district }) => {
  const phoneField = district.fields.find(f => f.key === 'homephone');
  return <>
    <VerticalCell cols={6}>
      <h2>Contact {index}</h2>
    </VerticalCell>
    <VerticalCell cols={3}>
      <label>Name</label>
      <Field name={`parent${index}Name`} />
    </VerticalCell>
    <VerticalCell cols={3}>
      <label>Phone Number</label>
      {phoneField.$mask && <Field name={`parent${index}Phone`} render={({ field }: any) => <MaskedField
          mask={phoneField.$mask}
          {...field} />
        } />}
      {!phoneField.$mask && <Field name={`parent${index}Phone`} />}
    </VerticalCell>
  </>;
};
