import { Document } from '~/documents/state';
import moment from 'moment';
import { District } from '~/customers/District';
import * as Yup from 'yup';

export interface AddEnrollmentFields {
  customerId?: number;
  dependentId: number;
  recaptchaResponse?: string;
  guid?: string;
  schoolPreference: string[];
  firstName: string;
  middleName: string;
  lastName: string;
  gradeLevel: string;
  homePhone: string;
  birthDate: string;
  entryDate: string;
  entryYear: string;
  email: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  sibling: boolean;
  swiftCode?: string;
  parent1Name: string;
  parent1Phone: string;
  parent2Name: string;
  parent2Phone: string;
  documents: Document[];
}

export class AddEnrollmentModel implements AddEnrollmentFields {
  customerId: number;
  dependentId: number;
  guid: string;
  recaptchaResponse: string = '';
  schoolPreference: string[] = [];
  firstName: string = '';
  middleName: string = '';
  lastName: string = '';
  gradeLevel: string = '';
  homePhone: string = '';
  birthDate: string = '';
  entryDate: string = '';
  entryYear: string = '';
  email: string = '';
  street: string = '';
  city: string = '';
  state: string = '';
  zip: string = '';
  sibling: boolean;
  swiftCode: string = '';
  parent1Name: string = '';
  parent1Phone: string = '';
  parent2Name: string = '';
  parent2Phone: string = '';
  documents: Document[] = [];

  constructor(values?: AddEnrollmentFields) {
    Object.assign(this, values || {});
  }

  serialize() {
    return {
      'customerId': this.customerId,
      'guid': this.guid,
      'g-recaptcha-response': this.recaptchaResponse,
      'schoolPreference': [...this.schoolPreference],
      'firstname': this.firstName,
      'middlename': this.middleName,
      'lastname': this.lastName,
      'gradelevel': this.gradeLevel,
      'phone': this.homePhone,
      'dob': this.birthDate ? moment(this.birthDate).format('MM/DD/YYYY') : '',
      'entrydate': this.entryDate ? moment(this.entryDate).format('MM/DD/YYYY') : '',
      'entryyear': this.entryYear,
      'email': this.email,
      'street': this.street,
      'city': this.city,
      'state': this.state,
      'zip': this.zip,
      'sibling': this.sibling,
      'swiftCode': this.swiftCode,
      'dependentId': this.dependentId || null,
      'documents': this.documents.map(({ guid, name }) => ({ guid, name })),
      'parentContacts': {
        contact1: {
          name: this.parent1Name,
          phone: this.parent1Phone
        },
        contact2: {
          name: this.parent2Name,
          phone: this.parent2Phone
        }
      }
    };
  }

  serializeForUpdate() {
    return {
      schoolPreference: [...this.schoolPreference],
      first_name: this.firstName,
      middle_name: this.middleName,
      last_name: this.lastName,
      grade_level: this.gradeLevel,
      home_phone: this.homePhone,
      dob: this.birthDate ? moment(this.birthDate).format('MM/DD/YYYY') : '',
      entry_date: this.entryDate ? moment(this.entryDate).format('MM/DD/YYYY') : '',
      entryyear: this.entryYear,
      email: this.email,
      street: this.street,
      city: this.city,
      state: this.state,
      zip: this.zip,
      sibling: this.sibling,
      swiftCode: this.swiftCode,
      dependentId: this.dependentId || null,
      documents: this.documents.map(({ guid, name }) => ({ guid, name })),
      parentContacts: {
        contact1: {
          name: this.parent1Name,
          phone: this.parent1Phone
        },
        contact2: {
          name: this.parent2Name,
          phone: this.parent2Phone
        }
      }
    };
  }
}

const table: { [key: string]: string } = {
  'g-recaptcha-response': 'recaptchaResponse',
  'schoolPreference': 'schoolPreference',
  'firstname': 'firstName',
  'middlename': 'middleName',
  'lastname': 'lastName',
  'gradelevel': 'gradeLevel',
  'homephone': 'homePhone',
  'dob': 'birthDate',
  'entrydate': 'entryDate',
  'entryyear': 'entryYear',
  'email': 'email',
  'street': 'street',
  'city': 'city',
  'state': 'state',
  'zip': 'zip',
  'sibling': 'sibling',
  'swiftCode': 'swiftCode',
  'dependentId': 'dependentId',
  'documents': 'documents'
};

// basic rule to centralize logic - these are the inclusive dates for registration on a given start year  - will need refactoring for FEBRUARY if rule changes
const EntryDateRangeRule = {
  startMonth: '07', startDay: '01',
  endMonth: '06', endDay: '30', endAddYears: 1
};

export function getEntryDateRangeRuleStart(startYear: string, daysOffset: number = 0) {
  let after = moment(`${startYear}-${EntryDateRangeRule.startMonth}-${EntryDateRangeRule.startDay}`, 'YYYY-MM-DD');
  // cannot start before present day
  if (after.isBefore(moment())) {
    after = moment();
  }
  if (daysOffset) {
    after = after.add(daysOffset, 'd');
  }
  return after;
}
export function getEntryDateRangeRuleEnd(startYear: string, daysOffset: number = 0) {
  let before = moment(`${Number.parseInt( startYear ) + EntryDateRangeRule.endAddYears}-${EntryDateRangeRule.endMonth}-${EntryDateRangeRule.endDay}`, 'YYYY-MM-DD');
  if (daysOffset) {
    before = before.add(daysOffset, 'd');
  }
  return before;
}

export function validationSchema(district: District, includeCaptcha: boolean) {
  const schema: any = {
    schoolPreference: Yup.array(),
    firstName: Yup.string(),
    middleName: Yup.string(),
    lastName: Yup.string(),
    gradeLevel: Yup.string(),
    homePhone: Yup.string(),
    birthDate: Yup.string().isDate().dateBefore(moment()),
    entryDate: Yup.string().isDate().when('entryYear', (entryYear: string, theschema: any) => {
      if (!entryYear) {
        // if entryyear is not specified, only ensure that entryDate is a date. EntryYear should show its own error condition
        return Yup.string().isDate();
      } else {
        let after = getEntryDateRangeRuleStart( entryYear, -1);
        const before = getEntryDateRangeRuleEnd( entryYear, 1);
        return Yup.string().required().isDate().dateAfter(after).dateBefore(before);
      }
    }),
    entryYear: Yup.string().required(),
    email: Yup.string(),
    street: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    sibling: Yup.boolean(),
    swiftCode: Yup.string(),
    parent1Name: Yup.string(),
    parent1Phone: Yup.string(),
    parent2Name: Yup.string(),
    parent2Phone: Yup.string(),
    documents: Yup.array()
  };

  if (includeCaptcha) {
    schema.recaptchaResponse = Yup.string().required('Please confirm you\'re not a robot by clicking the box at the bottom of the page.');
  }

  const options = district.schoolOptions;
  if (options.requireSwiftCode) {
    schema.swiftCode = schema.swiftCode.required();
  }

  const minSchools = options.schoolApproval ? options.approvalMinSchools : (options.allowPreferenceRequired ? 1 : 0);
  if (minSchools > 0) {
    schema.schoolPreference = schema.schoolPreference.min(minSchools).unique();
  }

  // Loop over all field settings to add optional validation rules
  district.fields.forEach(f => {
    let field = schema[table[f.key]];
    if (!field && f.key !== 'parentContacts') {
      console.warn(`Field [${f.key}] is unknown...`);
      return;
    }

    if (f.required && f.key !== 'sibling') {
      // the sibling fields is a boolean so no need to mark it as required
      field = field.required();
    }

    if (f.validation && !f.values && !['entrydate', 'dob'].includes(f.key)) {
      // prioritise the validation option over pattern.
      // ignore if the field is a date because we use actual date inputs for those.
      // create a regex string out of the validation pattern
      const pattern = f.validation.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&').replace(/0/g, '\\d').replace(/A/g, '[A-Z]');
      field = field.matches(new RegExp(`^${pattern}$`));
    }
    else if (f.pattern && !f.values) {
      field = field.matches(new RegExp(`^${f.pattern}$`));
    }

    if (f.key === 'parentContacts') {
      if (f.contact1 && f.contact1.enabled && f.contact1.required) {
        schema.parent1Name = schema.parent1Name.required();
        schema.parent1Phone = schema.parent1Phone.required();
      }
      if (f.contact2 && f.contact2.enabled && f.contact2.required) {
        schema.parent2Name = schema.parent2Name.required();
        schema.parent2Phone = schema.parent2Phone.required();
      }
    }

    schema[table[f.key]] = field;
  });

  return Yup.object().shape(schema);
}
