import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import { StyledTransitionGroup } from './CrossFade.styles';

interface Props {
  id: string;
}

export const CrossFade: FC<Props> = ({ id, children }) =>
  <StyledTransitionGroup>
    <CSSTransition key={id} timeout={{ enter: 300, exit: 300 }} classNames="cross-fade">
      {children}
    </CSSTransition>
  </StyledTransitionGroup>;
