import React, { FC } from 'react';
import { VerticalCell, Field, Cell } from '~/ui';
import { District } from '~/customers/District';
import { enrollmentYears } from '../actions/helpers';
import { AddEnrollmentModel, getEntryDateRangeRuleStart, getEntryDateRangeRuleEnd } from '../models/AddEnrollmentModel';
import { useOnChange } from '~/shared/hooks/useOnChange';
const years = enrollmentYears();

interface Props {
  setFieldValue: any;
  district: District;
  values: AddEnrollmentModel;
}

export const EnrollmentDetails: FC<Props> = ({ setFieldValue, district, values }) => {

  useOnChange(() => setFieldValue('entryDate', getEntryDateRangeRuleStart(values.entryYear).format('YYYY-MM-DD')), [values.entryYear]);

  const entryYearField = district.fields.find(f => f.key === 'entryyear');
  let entryYears = [years.currentYear, years.nextYear];
  if (entryYearField && entryYearField.options) {
    switch (entryYearField.options.displayYears) {
      case 'current':
        entryYears = [years.currentYear];
        break;
      case 'next':
        entryYears = [years.nextYear];
        break;
    }
  }

  const gradelevelField = district.fields.find(f => f.key === 'gradelevel');
  const gradeLevels = (gradelevelField && gradelevelField.$choices) || [];

  let minEntryDate  = values.entryYear ? getEntryDateRangeRuleStart(values.entryYear).format('YYYY-MM-DD') : null ;
  let maxEntryDate  = values.entryYear ? getEntryDateRangeRuleEnd(values.entryYear).format('YYYY-MM-DD') : null;

  return <>
    <VerticalCell cols={6}>
      <h2>Enrollment details</h2>
    </VerticalCell>
    <Cell cols={6}>
      <Field type="checkbox" name="sibling" id="sibling" checked={values.sibling} />
      <label htmlFor="sibling">
        Student has a sibling currently attending school in {district.districtName}
      </label>
    </Cell>
    <VerticalCell cols={3}>
      <label>Entry Year</label>
      <Field component="select" name="entryYear">
        {!values.entryYear && <option value=""></option>}
        {entryYears.map(y => <option key={y.year} value={y.year}>{y.name} ({y.year}-{y.year + 1})</option>)}
      </Field>
    </VerticalCell>
    <VerticalCell cols={3}>
      <label>Entry Date</label>
      <Field type="date" name="entryDate" min={minEntryDate} max={maxEntryDate} />
    </VerticalCell>
    <VerticalCell cols={3}>
      <label>Grade Level</label>
      {gradeLevels.length === 0 && <Field name="gradeLevel" />}
      {gradeLevels.length > 0 && <Field component="select" name="gradeLevel">
        {!values.gradeLevel && <option value=""></option>}
        {gradeLevels.map(g => <option key={g.id} value={g.id}>{g.name}</option>)}
      </Field>}
    </VerticalCell>
    {district.schoolOptions.requireSwiftCode && <VerticalCell cols={6}>
      <label>Enroll Code</label>
      <Field name="swiftCode" />
    </VerticalCell>}
  </>;
};
