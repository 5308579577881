import moment from 'moment';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Modal } from '~/ui/components/Modal';
import { Button, FlexSpacer } from '~/ui';
import { useDocuments, getDocumentsModule } from '~/documents';
import { Document } from '~/documents/state';
import { DocumentUpload } from '~/parentPortal/components/DocumentUpload';
import { withModules } from '~/shared/withModules';
import { MessageTd } from '~/parentPortal/components/Documents.styles';
import { Spinner } from '~/ui/components/Spinner';

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  button+button {
    margin-left: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction:column;
  h2 {
    flex: 1;
  }
`;

const List = styled.div`
  max-height: 250px;
  overflow-y: auto;
  position: relative;
`;

const ListHead = styled.div`
  display: grid;
  grid-template-columns: 30px auto 130px;
  width: 100%;
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: solid 1px var(--grey-bg);
  font-weight: bold;

  & > div {
    padding: 10px;
  }
`;

const ListContent = styled.div`
  display: grid;
  grid-template-columns: 30px auto 130px;
  width: 100%;

  & > div {
    padding: 10px;
  }
`;

interface LiProp {
  document: Document;
  selected: boolean;
  toggleSelection: (checked: boolean) => void;
}

const ListItem: FC<LiProp> = ({ document, selected, toggleSelection }) => {
  return  <>
      <div>
      <input
        type="checkbox"
        id={`cb-${document.guid}`}
        checked={selected}
        onChange={e => toggleSelection(e.target.checked)} />
    </div>
    <div><label htmlFor={`cb-${document.guid}`}>{document.name}</label></div>
    <div>{moment(document.uploadDate).format('MM/DD/YYYY')}</div>
  </>;
};

interface Props {
  enrollmentDocuments: Document[];
  setEnrollmentDocuments: ( documents: Document[]) => void;
}

export const DocumentSelector: FC<Props> = withModules([getDocumentsModule()], ( { enrollmentDocuments, setEnrollmentDocuments }) => {
  let [showModal, setShowModal] = useState(false);
  let [selectedDocs, setSelectedDocs] = useState( [...enrollmentDocuments]);
  const libraryDocs = useDocuments();
  const empty = !libraryDocs.data || !libraryDocs.data.length;

  const toggleModal = (evt: React.MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    setShowModal( !showModal);
    if (showModal) {
      setSelectedDocs( [...selectedDocs] );
    }
  };

  const toggleSelection = (document: Document, checked: boolean) => {
    const pos = selectedDocs.indexOf( document );
    if ( pos > -1 ) {
      const copySelectedDocs = [...selectedDocs];
      copySelectedDocs.splice(pos, 1);
      setSelectedDocs( copySelectedDocs );
    } else {
      setSelectedDocs( [...selectedDocs, document ]);
    }
  };

  const save = () => {
    setEnrollmentDocuments( [...selectedDocs]);
    setShowModal(false);
  };

  // lists documents in the main user's lib of documents or upload a new one
  return <>
    <Button className="small" onClick={e => toggleModal(e)}>Add / Remove Documents</Button>
    {showModal &&
    <Modal
      header={
        <Header>
          <h2>Select documents</h2>
          <DocumentUpload />
        </Header>
      }
      footer={
        <>
          <FlexSpacer />
          <Button onClick={e => toggleModal(e)}>Cancel</Button>
          <Button kind="primary" disabled={selectedDocs.length === 0 && enrollmentDocuments && enrollmentDocuments.length === 0}  onClick={() => save()}>Ok</Button>
        </>
      }>

        {(empty && !libraryDocs.reading) && <>
            <MessageTd colSpan={3}>
            There are no documents in your library. You can upload new documents using the button above.
            </MessageTd>
          </>}
          {(empty && libraryDocs.reading) && <>
            <MessageTd colSpan={3}>
              <Spinner size="medium" />
            </MessageTd>
          </>}

      {!empty && <>
        <p>You can select documents from your library below or upload new documents using the button above </p>
        <List>
          <ListHead>
            <div></div>
            <div>Name</div>
            <div>Uploaded</div>
          </ListHead>
          <ListContent>
            {!empty && libraryDocs.data.map(d =>
              <ListItem key={d.guid} document={d} selected={selectedDocs.includes(d)} toggleSelection={(checked) => toggleSelection(d, checked)} />)}
          </ListContent>
        </List>
      </>}
    </Modal>
    }
  </>;
});
