import React from 'react';
import { Field, VerticalCell } from '~/ui';

export const StudentInformation = () => <>
  <VerticalCell cols={6}>
    <h2>Student Information</h2>
  </VerticalCell>
  <VerticalCell cols={3}>
    <label>First Name</label>
    <Field name="firstName" />
  </VerticalCell>
  <VerticalCell cols={3}>
    <label>Middle Name</label>
    <Field name="middleName" />
  </VerticalCell>
  <VerticalCell cols={3}>
    <label>Last Name</label>
    <Field name="lastName" />
  </VerticalCell>
  <VerticalCell cols={3}>
    <label>Date of birth</label>
    <Field type="date" name="birthDate" />
  </VerticalCell>
</>;
