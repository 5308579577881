import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { updateDependent, useDependent } from '~/dependents';
import { Button, Cell, FlexSpacer, Grid, PageWithHeader, ValidationErrors, VerticalCell, NotFound, Theme, Flex } from '~/ui';
import { DependentEnrollments } from './DependentEnrollments/DependentEnrollments';
import { DependentForm } from './DependentForm';

const ContainerInfo = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-items: start;
  gap: 40px;
  padding:20px;
  box-shadow: 0 0 8px 0 ${Theme.BoxShadow};
  margin: 20px
`;

const ContainerEnrollment = styled.div`
  padding: 20px;
  min-width: 300px;
`;

interface Props {
  id: string;
}

export const DependentDetails: FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const dependent = useDependent(id);

  if (!dependent) return <NotFound />;

  return <PageWithHeader header={<h1>{dependent.firstName}</h1>}>
    <Flex>
        <ContainerInfo>
          <Formik
            initialValues={dependent}
            enableReinitialize
            onSubmit={values => dispatch(updateDependent(values))}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required('Required'),
              lastName: Yup.string().required('Required')
            })}
            render={({ values, setFieldValue }) =>
              <Form>
                <Grid cols={6}>
                  <VerticalCell cols={6}>
                    <h2>Personal Information</h2>
                  </VerticalCell>
                  <VerticalCell cols={6}>
                    <ValidationErrors />
                  </VerticalCell>
                  <DependentForm {...{ values, setFieldValue }} />
                  <Cell cols={6}>
                    <FlexSpacer />
                    <Button kind="primary" type="submit">Save</Button>
                  </Cell>
                </Grid>
              </Form>
            } />
      </ContainerInfo>
      <ContainerEnrollment>
        <DependentEnrollments dependentId={id} />
      </ContainerEnrollment>
      </Flex>
  </PageWithHeader>;
};
