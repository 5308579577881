import styled from 'styled-components';

interface Props {
  cols?: number;
}

export const Cell = styled.div<Props>`
  grid-column: span ${p => p.cols || 1};
  display: flex;
  flex-direction: row;
  align-items: center;

  &:empty {
    display: none;
  }

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
