import { IModule } from 'redux-dynamic-modules';
import { reduceReducers } from '~/shared/reduceReducers';
import { addDependentReducer } from './actions/addDependent';
import { deleteDependentReducer } from './actions/deleteDependent';
import { listDependentsReducer, listDependents } from './actions/listDependents';
import { updateDependentReducer } from './actions/updateDependent';
import { State } from './state';

export function getDependentsModule(): IModule<State> {
  return {
    id: 'dependents',
    reducerMap: {
      dependents: reduceReducers(
        addDependentReducer,
        updateDependentReducer,
        listDependentsReducer,
        deleteDependentReducer
      )
    },
    initialActions: [
      listDependents() as any
    ]
  };
}
