import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

export const StyledTransitionGroup = styled(TransitionGroup as any)`
	display: grid;
	overflow: hidden;
	grid-template: auto / auto;
	> * {
		grid-column: 1;
		grid-row: 1;
	}
	.cross-fade-exit {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
	.cross-fade-exit.cross-fade-exit-active {
		opacity: 0;
		transform: translate3d(0, 30px, 0);
		transition: all .3s ease-out;
	}
	.cross-fade-enter {
		opacity: 0;
		transform: translate3d(0, 30px, 0);
	}
	.cross-fade-enter.cross-fade-enter-active {
		opacity: 1;
		transition: all .3s ease-in;
		transform: translate3d(0, 0, 0);
	}
`;
