import React from 'react';
import * as ReactDOM from 'react-dom';
import { Routes } from './routes';
import { Splash } from './splash';
import { StoreProvider } from './StoreProvider';
import { GlobalStyle } from './ui';
import { NotificationsHost } from './notifications/components/NotificationsHost';
import './shared/validation';

ReactDOM.render(
  <StoreProvider>
    <GlobalStyle />
    <Splash>
      <NotificationsHost />
      <Routes />
    </Splash>
  </StoreProvider>,
  document.getElementById('root')
);

if (module.hot) module.hot.accept();
