import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useResetPassword } from '../hooks/useResetPassword';
import { PageCenter, Box, Spinner, Grid, VerticalCell, ValidationErrors, Field, Cell, FlexSpacer, Button, ErrorPane } from '~/ui';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

export const DoResetPassword = () => {
  const [status, request] = useResetPassword();
  const params = new URLSearchParams(location.search);

  let content: ReactNode;
  if (status === 'done') {
    content = <>
      <p>Your password has been updated.</p>
      <p><Link to="/login">Back to login</Link></p>
    </>;
  }
  else {
    content = <>
      <p>
        Please enter your new password below.
      </p>
      {status === 'error' && <ErrorPane>
        An unexpected error has occurred.
      </ErrorPane>}
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={({ password }) => request(params.get('token'), password)}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .test(
              'pwd-rules',
              'must be at least 6 characters long and contain at least two of the following: a lowercase, uppercase, number and special character',
              value => {
                let constraintCount = 0;
                if (/[A-Z]/.test(value)) constraintCount++;
                if (/[a-z]/.test(value)) constraintCount++;
                if (/\d/.test(value)) constraintCount++;
                if (/\W/.test(value)) constraintCount++;

                return constraintCount >= 2 && value.length >= 6;
              })
            .required('Required'),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Must match password')
            .required('Required')
        })}
        validateOnBlur={false}>
        {() => <Form>
          <Grid>
            <VerticalCell>
              <ValidationErrors />
            </VerticalCell>
            <VerticalCell>
              <Field type="password" name="password" placeholder="Password" />
            </VerticalCell>
            <VerticalCell>
              <Field type="password" name="confirmPassword" placeholder="Confirm password" />
            </VerticalCell>
            <Cell>
              <FlexSpacer />
              <Button type="submit" kind="primary" disabled={status === 'requesting'}>Continue</Button>
            </Cell>
          </Grid>
        </Form>}
      </Formik>
    </>;
  }

  return <PageCenter>
    <Box width="medium">
      <h1>Password reset</h1>
      {content}
    </Box>
  </PageCenter>;
};
