import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../Theme';

interface Props {
  size?: 'small' | 'medium' | 'large';
}

const Svg = styled.svg<Props>`
  animation: rotator 5s linear infinite;
  transform-origin: center;
  margin: 10px;

  ${p => {
    switch (p.size) {
      case 'small':
        return css`
          width: 18px;
          height: 18px;
        `;
      case 'medium':
        return css`
          width: 50px;
          height: 50px;
        `;
      case 'large':
        return css`
          width: 100px;
          height: 100px;
        `;
    }
  }}

  @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes dash {
    0% { stroke-dashoffset: 265; }
    50% {
      stroke-dashoffset: 65;
      transform:rotate(90deg);
    }
    100% {
      stroke-dashoffset: 265;
      transform:rotate(360deg);
    }
  }
`;

const Circle = styled.circle`
  stroke-dasharray: 265;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: ${Theme.Primary};
  animation: dash 1s ease-in-out infinite;
`;

export const Spinner: FC<Props> = ({ size = 'small' }) =>
  <Svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" size={size} >
    <Circle fill="none" strokeWidth="10" strokeLinecap="butt" cx="50" cy="50" r="40" />
  </Svg>;
