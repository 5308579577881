import { IModule } from 'redux-dynamic-modules';
import { reducer } from './reducer';
import { State } from './State';

export function getNotificationsModule(): IModule<State> {
  return {
    id: 'notifications',
    reducerMap: {
      notifications: reducer
    }
  };
}
