import { Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { Button, Cell, Field, FlexSpacer, Grid, LinkButton, Theme, ValidationErrors, VerticalCell } from '~/ui';
import { RegistrationModel } from '../models/RegistrationModel';

interface Props {
  initialValues: RegistrationModel;
  onSubmit: (values: RegistrationModel) => void;
}

const StyledButton = styled(Button)`
  margin-left: ${Theme.Gap};
`;

export const RegistrationForm = (props: Props) => {
  return <Formik
    {...props}
    validationSchema={props.initialValues.validationSchema}
  >
    <Form>
      <Grid cols={2}>
        <VerticalCell cols={2}>
          <ValidationErrors />
        </VerticalCell>
        <VerticalCell>
          <label>First Name</label>
          <Field type="text" name="firstName" />
        </VerticalCell>
        <VerticalCell>
          <label>Last Name</label>
          <Field type="text" name="lastName" />
        </VerticalCell>
        <VerticalCell>
          <label>Email</label>
          <Field type="email" name="email" />
        </VerticalCell>
        <Cell>&nbsp;</Cell>
        <VerticalCell>
          <label>Password</label>
          <Field type="password" name="password" />
        </VerticalCell>
        <VerticalCell>
          <label>Confirm Password</label>
          <Field type="password" name="confirmPassword" />
        </VerticalCell>
        <Cell cols={2}>
          <FlexSpacer />
          <LinkButton to="/login">Cancel</LinkButton>
          <StyledButton type="submit" kind="primary">Continue</StyledButton>
        </Cell>
      </Grid>
    </Form>
  </Formik>;
};
