import React, { FC } from 'react';
import { VerticalCell, MaskedField, Field } from '~/ui';
import { localRules } from '~/shared/localRules';
import { countries } from '~/shared/countries';
import { Dependent } from '~/dependents/state';
import { useOnChange } from '~/shared/hooks/useOnChange';

interface Props {
  values: Dependent;
  setFieldValue: (field: keyof Dependent, value: any, shouldValidate?: boolean) => void;
}

export const DependentForm: FC<Props> = ({ values, setFieldValue }) => {
  useOnChange(() => setFieldValue('zip', ''), [values.country]);

  return <>
    <VerticalCell cols={3}>
      <label>First name</label>
      <Field type="text" name="firstName" />
    </VerticalCell>
    <VerticalCell cols={3}>
      <label>Middle name</label>
      <Field type="text" name="middleName" />
    </VerticalCell>
    <VerticalCell cols={3}>
      <label>Last name</label>
      <Field type="text" name="lastName" />
    </VerticalCell>
    <VerticalCell cols={3}>
      <label>Date of birth</label>
      <Field type="date" name="birthDate" />
    </VerticalCell>
    <VerticalCell cols={3}>
      <label>Phone number</label>
      <Field name="phoneNumber" render={({ field }: any) => <MaskedField
        mask={values.country && localRules[values.country].phoneMask}
        {...field} />
      } />
    </VerticalCell>
    <VerticalCell cols={3}>
        <label></label>
      </VerticalCell>
    <VerticalCell cols={3}>
      <label>Country</label>
      <Field component="select" name="country" >
        {!values.country && <option />}
        {countries.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
      </Field>
    </VerticalCell>
    <VerticalCell cols={6}>
      <label>Address</label>
      <Field type="text" name="address" />
    </VerticalCell>
    <VerticalCell cols={4}>
      <label>City</label>
      <Field type="text" name="city" />
    </VerticalCell>
    <VerticalCell cols={3}>
      <label>{values.country === 'USA' ? 'State' : 'Province'}</label>
      <Field component="select" name="state" disabled={!values.country} >
        {!values.state && <option />}
        {values.country && countries.find(c => c.id === values.country).states.map(
          s => <option key={s.id} value={s.id}>{s.name}</option>
        )}
      </Field>
    </VerticalCell>
    <VerticalCell cols={2}>
      <label>{values.country === 'USA' ? 'ZIP' : 'Postal code'}</label>
      <Field name="zip" render={({ field }: any) => <MaskedField
        mask={values.country && localRules[values.country].zipMask}
        {...field} />
      } />
    </VerticalCell>
  </>;
};
