import { useState, useEffect } from 'react';
import { post } from '~/shared/http';

type Status = 'requesting' | 'done' | 'invalidToken' | 'error';

export function useActivateAccount(token: string) {
  const [status, setStatus] = useState<Status>();

  useEffect(() => {
    if (!token) {
      setStatus('invalidToken');
      return;
    }
    setStatus('requesting');
    post('/api/am/account/activate', { token }).then(response => {
      switch (response.status) {
        case 200:
          setStatus('done');
          return;
        case 404:
          setStatus('invalidToken');
          return;
        default:
          setStatus('error');
          return;
      }
    });
  }, []);

  return status;
}
