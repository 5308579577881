import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import * as Yup from 'yup';
import { addDependent } from '~/dependents';
import { Dependent } from '~/dependents/state';
import { Button, Cell, FlexSpacer, Form, Grid, PageWithHeader, ValidationErrors, VerticalCell } from '~/ui';
import { DependentForm } from './DependentForm';
import { useAuth } from '~/auth/hooks/useAuth';

export const NewDependent = withRouter(({history}) => {
  const dispatch = useDispatch();
  const userInfo = useAuth().userInfo;
  return <PageWithHeader header={<h1>New family member</h1>}>
    <Formik
      initialValues={{
        firstName: '',
        middleName: '',
        lastName: '',
        address: userInfo ? userInfo.address || '' : '',
        city: userInfo ? userInfo.city || '' : '',
        state: userInfo ? userInfo.state || '' : '',
        country: userInfo ? userInfo.country || 'USA' : 'USA',
        zip: userInfo ? userInfo.zip || '' : '',
        gender: '',
        birthDate: '',
        ethnicity: '',
        phoneNumber: userInfo ? userInfo.phoneNumber : ''
      } as Dependent}
      onSubmit={values => dispatch(addDependent(values, history))}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required')
      })}
      render={({ values, setFieldValue }) =>
        <Form>
          <Grid cols={6}>
            <VerticalCell cols={6}>
              <ValidationErrors />
            </VerticalCell>
            <DependentForm {...{ values, setFieldValue }} />
            <Cell cols={6}>
              <FlexSpacer />
              <Button kind="primary" type="submit">Save</Button>
            </Cell>
          </Grid>
        </Form>
      } />
  </PageWithHeader>;
});
