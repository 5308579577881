export let ServiceUrls: {
  cmp: string,
  am: string,
  doc: string,
  enr: string
};

if (process.env.API_ENV === 'production') {
  ServiceUrls = {
    cmp: 'https://cmp.accelaschool.com',
    am: 'https://accounts.accelaschool.com',
    doc: 'https://documents.accelaschool.com',
    enr: 'https://ecollect.accelaschool.com'
  };
}
else if (process.env.API_ENV === 'staging') {
  ServiceUrls = {
    cmp: 'https://qa2-cmp.accelaschool.com',
    am: 'https://qa2-am.accelaschool.com',
    doc: 'https://qa2-doc.accelaschool.com',
    enr: 'https://qa2-enr.accelaschool.com'
  };
}
else if (process.env.API_ENV === 'development_cloud') {
  ServiceUrls = {
    cmp: 'https://dev-cmp.accelaschool.com',
    am: 'https://dev-am.accelaschool.com',
    doc: 'https://dev-da.accelaschool.com',
    enr: 'https://dev-enr.accelaschool.com'
  };
}
else {
  ServiceUrls = {
    cmp: 'http://localhost:3000',
    am: 'http://localhost:3500',
    doc: 'http://localhost:5000',
    enr: 'http://localhost:4000'
  };
}
