import { Dispatch, Reducer } from 'redux';
import { State } from '~/dependents/state';
import { del } from '~/shared/http';
import { EnrollmentsState } from '../state';
import { notify } from '~/notifications/actions';
import { listEnrollments } from './listEnrollments';

export const REMOVE_ENROLLMENT_REQUEST = 'REMOVE_ENROLLMENT_REQUEST';
const REMOVE_ENROLLMENT_SUCCESS = 'REMOVE_ENROLLMENT_SUCCESS';
const REMOVE_ENROLLMENT_FAILURE = 'REMOVE_ENROLLMENT_FAILURE';

const request = (guid: string) => ({
  type: REMOVE_ENROLLMENT_REQUEST,
  guid
});

const success = (guid: string) => ({
  type: REMOVE_ENROLLMENT_SUCCESS,
  guid
});

const failure = () => ({
  type: REMOVE_ENROLLMENT_FAILURE
});

export const removeEnrollment = (guid: string) =>
  async (dispatch: Dispatch<any>, getState: () => State) => {
    await dispatch(request(guid));
    try {
      const response = await del(`api/enr/api/v2/enrollments/${guid}`);
      if (response.status !== 200) throw Error('failed');
      await dispatch(success(guid));
      await dispatch(listEnrollments());
    }
    catch (e) {
      await dispatch(failure());
      await dispatch(notify(
        'Error',
        'Unexpected error while withdrawing enrollment',
        REMOVE_ENROLLMENT_FAILURE,
        'error'));
    }
  };

export const removeEnrollmentReducer: Reducer<EnrollmentsState> = (state = [], action) => {
  switch (action.type) {
    case REMOVE_ENROLLMENT_SUCCESS:
      return state.filter(e => e.guid !== action.guid);
    default:
      return state;
  }
};
