import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux-dynamic-modules';
import { getThunkExtension } from 'redux-dynamic-modules-thunk';
import { getAuthModule } from './auth';
import { getNotificationsModule } from './notifications';
import { getApiStatusModule } from './apiStatus';

const store = createStore({
    initialState: {},
    extensions: [getThunkExtension()]
  },
  {
    id: 'features',
    reducerMap: {
      features: (s = { loginOnly: process.env.LOGIN_ONLY !== 'false' }) => s
    }
  },
  getApiStatusModule(),
  getNotificationsModule(),
  getAuthModule()
);

export const StoreProvider: FC = props => <Provider store={store}>{props.children}</Provider>;
