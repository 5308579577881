import React, { FC } from 'react';
import { VerticalCell, Field, Select, Flex } from '~/ui';
import { District } from '~/customers/District';
import { Sanitize } from '~/ui/components/Sanitize';
import { FieldArray } from 'formik';
import styled from 'styled-components';

const RemoveButton = styled.button`
  border: none;
  background: transparent;
  font-size: 1.3rem;
  margin-left: 15px;
  cursor: pointer;
`;

const FlexField = styled(Field)`
  flex: 1;
`;
const SelectContentWidth = styled( Select )`
  flex:1;
  align-self: baseline;
`;

interface Props {
  district: District;
  schoolPreference: string[];
}

export const SchoolPreference: FC<Props> = ({ district, schoolPreference }) => {
  const options = district.schoolOptions;
  const schools = district.schools;
  if (!schools || (!options.allowPreference && (!options.schoolApproval || !options.approvalMaxSchools))) return <></>;

  const maxSchools = options.schoolApproval ? options.approvalMaxSchools : 1;

  /**
   * Renders a list of schools which haven't yet been selected, optionally including the currently selected one.
   */
  const schoolList = (selection?: string) => <>
    {schools
        .filter(s => schoolPreference.indexOf(s.SisId) < 0 || s.SisId === selection)
        .map(s => <option key={s.SisId} value={s.SisId}>{s.SchoolName}</option>)}
  </>;

  return <>
    <VerticalCell cols={6}>
      <h2>School Preference</h2>
    </VerticalCell>
    {options.schoolPreferenceText && <VerticalCell cols={6}>
      <Sanitize html={options.schoolPreferenceText} />
    </VerticalCell>}
    <FieldArray name="schoolPreference" render={arrayHelpers => <>

      {/* The list of selected schools */}
      {schoolPreference.map((p, i) => <VerticalCell key={i} cols={6}>
        <label>School</label>
        <Flex>
          <FlexField component="select" name={`schoolPreference.${i}`}>{schoolList(schoolPreference[i])}</FlexField>
          {maxSchools > 1 && <RemoveButton type="button" title="Remove this school" onClick={() => arrayHelpers.remove(i)}>&times;</RemoveButton>}
        </Flex>
      </VerticalCell>)}

      {/* Adding a school to the list */}
      {schoolPreference.length < maxSchools && <VerticalCell cols={6}>
        <label>School</label>
        <SelectContentWidth onChange={(e: any) => { arrayHelpers.push(e.target.value); e.target.value = ''; }}>
          <option value=""></option>
          {schoolList()}
        </SelectContentWidth>
      </VerticalCell>}
    </>} />
  </>;
};
