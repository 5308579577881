import { faFileAlt, faFileDownload, faFileImage, faFilePdf, faFileWord, faTrashAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { deleteDocument, getDocumentsModule, useDocuments } from '~/documents';
import { withModules } from '~/shared/withModules';
import { Cell, ErrorPane, FlexSpacer, PageWithHeader, Spinner } from '~/ui';
import { MessageTd, Row, Wrapper, Table } from './Documents.styles';
import { DocumentUpload } from './DocumentUpload';

const icons: { [key: string]: IconDefinition } = {
  pdf: faFilePdf,
  rtf: faFileAlt,
  doc: faFileWord,
  docx: faFileWord,
  png: faFileImage,
  jpg: faFileImage,
  jpeg: faFileImage,
};

export const Documents = withModules([getDocumentsModule()], () => {
  const documents = useDocuments();
  const dispatch = useDispatch();
  const empty = !documents.data || !documents.data.length;
  let content: ReactNode;

  function deleteDoc(guid: string) {
    if (!confirm('Permanently delete this file?')) {
      return;
    }
    dispatch(deleteDocument(guid));
  }

  return <PageWithHeader header={
    <>
      <h1>Documents</h1>
      <FlexSpacer />
      <DocumentUpload acceptedFileTypes={'.doc, .docx, .rtf, .png, .jpeg, .jpg .pdf'} maxFileSize={'5MB'} />
    </>
  }>
    <Wrapper>
      <Table>
        <thead>
          <Row>
            <th>Name</th>
            <th>Uploaded</th>
            <th></th>
          </Row>
        </thead>
        <tbody>
          {(empty && !documents.reading) && <tr>
            <MessageTd colSpan={3}>
              You haven't uploaded any document yet.
            </MessageTd>
          </tr>}
          {(empty && documents.reading) && <tr>
            <MessageTd colSpan={3}>
              <Spinner size="medium" />
            </MessageTd>
          </tr>}
          {!empty && documents.data.map(d =>
            <Row key={d.guid}>
              <td><a href={`/api/doc/documents/doc/${d.guid}?preview=true`} target="_blank"><FontAwesomeIcon icon={icons[d.name.replace(/^.*\.(.*)$/, '$1').toLowerCase()]}/> {d.name}</a> </td>
              <td>{moment(d.uploadDate).format('MM/DD/YYYY')}</td>
              <td>
                <a href={`/api/doc/documents/doc/${d.guid}`}><FontAwesomeIcon icon={faFileDownload} /></a>
                <a onClick={() => deleteDoc(d.guid)}><FontAwesomeIcon icon={faTrashAlt} /></a>
              </td>
            </Row>
          )}
        </tbody>
      </Table>
      {((!empty && documents.reading) || documents.creating) &&
        <Cell><Spinner /> {documents.creating ? 'Uploading' : 'Refreshing'}...</Cell>
      }
    </Wrapper>
  </PageWithHeader>;
});
