import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { Li, StyledNavLink } from './MenuLink.styles';

interface Props extends NavLinkProps {
  label: string;
  icon?: IconProp;
}

export const MenuLink: FC<Props> = ({ label, icon, children, ...props }) =>
  <Li>
    <StyledNavLink {...props}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {label}
    </StyledNavLink>
    {children}
  </Li>;
