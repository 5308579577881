import React, { FunctionComponent } from 'react';
import { PageCenter, Spinner } from '~/ui';
import { useAuth } from './auth';

/**
 * Shows a loading screen until the auth service is initialized
 */
export const Splash: FunctionComponent = props => {
  const initialized = useAuth().initialized;

  if (!initialized) {
    return <PageCenter>
      <div>
        <Spinner size="large" />
        <h2>Loading...</h2>
      </div>
    </PageCenter>;
  }

  return <>{props.children}</>;
};
