import React, { FC } from 'react';
import styled from 'styled-components';
import { District } from '~/customers/District';
import { Cell } from '~/ui';

const Logo = styled.img`width:100%;`;

const SidePane = styled.div`
  border: solid 1px var(--blue-accent);
  border-radius: 3px;
  background: var(--light-blue-accent);
  padding: 20px;
`;

function pane(card: { text?: string } = {}) {
  const text = card && card.text;
  if (text) {
    return <SidePane>{text}</SidePane>;
  }

  return <div />;
}

function logo(path: string) {
  if (logo) {
    return <Logo src={path} />;
  }

  return <div />;
}

interface Props {
  district: District;
}

export const DistrictLogo: FC<Props> = ({ district }) => <>
  <Cell cols={2}>
    {pane(district.card.left)}
  </Cell>
  <Cell cols={2}>
    {logo(district.logo && district.logo.path)}
  </Cell>
  <Cell cols={2}>
    {pane(district.card.right)}
  </Cell>
</>;
