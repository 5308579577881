import { useEffect, useRef } from 'react';

export function useOnChange(effect: () => void, values: any[]) {
  const firstRun = useRef(true);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    effect();
  }, values);
}
