import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Item, Content } from './MenuItem.styles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  icon?: IconProp;
}

export const MenuItem: FC<Props> = ({ label, icon, children }) =>
  <li>
    <Item>
      {icon && <FontAwesomeIcon icon={icon} />}
      {label}
    </Item>
    {children && <Content>{children}</Content>}
  </li>;
