import { useDispatch, useSelector } from 'react-redux';
import { notify } from './actions';
import { State } from './State';

export function useNotify() {
  const dispatch = useDispatch();

  return {
    info: (message: string | string[], title = 'Information', reason = '') => dispatch(notify(title, message, reason, 'info')),
    warning: (message: string | string[], title = 'Warning', reason = '') => dispatch(notify(title, message, reason, 'warning')),
    error: (message: string | string[], title = 'Error', reason = '') => dispatch(notify(title, message, reason, 'error')),
    success: (message: string | string[], title = 'Success', reason = '') => dispatch(notify(title, message, reason, 'success'))
  };
}

export function useNotifications() {
  return useSelector((s: State) => s.notifications);
}
