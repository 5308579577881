import { faAngleDown, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useUserInfo, useAuth } from '~/auth';
import { logout } from '~/auth/actions/logout';
import { useDocumentClick } from '~/shared/hooks/useDocumentClick';
import { Menu, CrossFade } from '~/ui';
import { MenuItem, Popup, Toggle, Wrapper } from './UserMenu.styles';
import { CSSTransition } from 'react-transition-group';

export const UserMenu = () => {
  const dispatch = useDispatch();
  const [popupVisible, setPopupVisisbility] = useState(false);
  const userInfo = useUserInfo();
  const auth = useAuth();
  useDocumentClick(() => setPopupVisisbility(false), popupVisible);

  if (!auth.authenticated) return <></>;

  return <Wrapper>
    <Toggle onClick={() => setPopupVisisbility(!popupVisible)}>
      Hello, {userInfo.firstName || userInfo.email}<FontAwesomeIcon icon={faAngleDown} />
    </Toggle>
    <CSSTransition in={popupVisible} unmountOnExit timeout={300} classNames="popup">
      <Popup>
        <Menu>
          <MenuItem>
            <Link to="/family/account">
              <FontAwesomeIcon icon={faUser} />
              Account
            </Link>
          </MenuItem>
          <MenuItem>
            <a onClick={() => dispatch(logout())}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              Log Out
            </a>
          </MenuItem>
        </Menu>
      </Popup>
    </CSSTransition>
  </Wrapper >;
};
