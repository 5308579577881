import React, { FunctionComponent } from 'react';
import { Redirect, RedirectProps } from 'react-router';
import { useAuth } from '../hooks/useAuth';

export const PrivateRedirect: FunctionComponent<RedirectProps> = props => {
  const auth = useAuth();

  if (auth.authenticated) {
    return <Redirect {...props} />;
  }
  else {
    return <Redirect to="/login" />;
  }
};
