import { Severity } from './State';
import { ADD_NOTIFICATION, DEL_NOTIFICATION, NOTIFICATION_VIEWED } from './constants';
import { Dispatch } from 'redux';

export const addNotification = (title: string, message: string[], reason: string, severity: Severity) => ({
  type: ADD_NOTIFICATION,
  id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
  timeStamp: Date.now(),
  title,
  message,
  reason,
  severity
});

export const deleteNotification = (id: string) => ({
  type: DEL_NOTIFICATION,
  id
});

export const notificationViewed = (id: string) => ({
  type: NOTIFICATION_VIEWED,
  id
});

export function notify(title: string, message: string | string[], reason: string, severity: Severity) {
  return async (dispatch: Dispatch<any>) => {
    const messages = message instanceof Array ? message : [message];
    const action = addNotification(title, messages, reason, severity);
    await dispatch(action);
    if (severity === 'info' || severity === 'success') {
      setTimeout(() => {
        dispatch(deleteNotification(action.id));
      }, 5000);
    }
  };
}
