import { Dispatch, Reducer } from 'redux';
import { notify } from '~/notifications/actions';
import { post } from '~/shared/http';
import { AuthState } from '../State';

const RESEND_EMAIL_REQUEST = 'auth/RESEND_EMAIL_REQUEST';
const RESEND_EMAIL_SUCCESS = 'auth/RESEND_EMAIL_SUCCESS';
const RESEND_EMAIL_FAILURE = 'auth/RESEND_EMAIL_FAILURE';

const resendEmailRequest = () => ({
  type: RESEND_EMAIL_REQUEST
});

const resendEmailSuccess = () => ({
  type: RESEND_EMAIL_SUCCESS
});

const resendEmailError = () => ({
  type: RESEND_EMAIL_FAILURE
});

export function resendEmail(email: string) {
  return async (dispatch: Dispatch<any>) => {
    await dispatch(resendEmailRequest());
    const response = await post('/api/am/account/resend-activation-email', { email });
    if (response.status === 200) {
      await dispatch(resendEmailSuccess());
      return;
    }

    let error: string;
    switch (response.status) {
      case 404:
        error = 'Invalid email';
        break;
      case 400:
        error = 'Account already activated';
        break;
      default:
        error = 'Unexpected error';
    }

    await dispatch(resendEmailError());
    await dispatch(notify(
      'Resend activation email',
      error,
      RESEND_EMAIL_FAILURE,
      'error'));
  };
}

export const resendEmailReducer: Reducer<AuthState> = (state, action) => {
  switch (action.type) {
    case RESEND_EMAIL_REQUEST:
      return {
        ...state,
        requestingEmail: true
      };
    case RESEND_EMAIL_SUCCESS:
    case RESEND_EMAIL_FAILURE:
      return {
        ...state,
        requestingEmail: false
      };
    default:
      return state;
  }
};
