import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { getDependentsModule, useDependents } from '~/dependents';
import { withModules } from '~/shared/withModules';
import { CrossFade, NotFound, PageCenter, Spinner } from '~/ui';
import { DependentDetails } from './components/DependentDetails';
import { Documents } from './components/Documents';
import { Home } from './components/Home';
import { NewDependent } from './components/NewDependent';
import { UserAccountDetails } from './components/UserAccountDetails';
import { withLayout } from './hoc/withLayout';
import { Nav } from './components/Nav';
import { EditEnrollment } from './components/EditEnrollment/EditEnrollment';

export const Routes = withModules([getDependentsModule()],
  withLayout(<Nav />,
    withRouter(({ location }) => {
      const dependents = useDependents();

      if (dependents.reading) {
        return <PageCenter><Spinner size="medium" /></PageCenter>;
      }

      return <CrossFade id={location.pathname}>
        <Switch {...{ location }}>
          <Route path="/family" exact component={Home} />
          <Route path="/family/dependents/new" exact component={NewDependent} />
          <Route path="/family/dependents/:id" exact render={({ match }) => <DependentDetails id={match.params.id} />} />
          <Route path="/family/dependents/:id/enrollments/:guid" exact render={({ match }) => <EditEnrollment id={match.params.id} guid={match.params.guid} />} />
          <Route path="/family/documents" exact component={Documents} />
          <Route path="/family/account" exact component={UserAccountDetails} />
          <Route path="/family" component={NotFound} />
        </Switch>
      </CrossFade>;
    })
  )
);
