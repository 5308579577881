import React, { FC } from 'react';
import { District, convertDistrictCountryCodeToDependentCountry } from '~/customers/District';
import { Dependent } from '~/dependents/state';
import { EnrollmentForm, useAddEnrollment } from '~/enrollments';
import { AddEnrollmentFields, AddEnrollmentModel, getEntryDateRangeRuleStart } from '~/enrollments/models/AddEnrollmentModel';
import { PageCenter, Spinner } from '~/ui';
import moment from 'moment';
interface Props {
  district: District;
  dependent: Dependent;
  guardianEmail: string;
  selectedYear: string;
}

export const AddEnrollment: FC<Props> = ({ district, dependent, guardianEmail, selectedYear }) => {
  const [addEnrollment, status] = useAddEnrollment();

  let initialValues: AddEnrollmentModel;
  if (!dependent) {
    initialValues = new AddEnrollmentModel();
  } else {

    initialValues = new AddEnrollmentModel({
      customerId: district.id,
      dependentId: dependent.id,
      firstName: dependent.firstName,
      lastName: dependent.lastName,
      middleName: dependent.middleName,
      // gender ??
      birthDate: dependent.birthDate,
      // ethnicity ??
      email: guardianEmail,
      entryYear: selectedYear,
      entryDate: getEntryDateRangeRuleStart( selectedYear ).format( 'YYYY-MM-DD'),
    } as AddEnrollmentFields);
  }

  // if the dependents info is in a different country than the selected district's country
  // then do not copy 'household info' to the enrollment.  The enrollment reflects
  // the country of the district, therefore copying information from a different country
  // will 1) potentially break input masks and 2) is not supported for enrollment purposes
  if ( dependent.country && ( dependent.country === convertDistrictCountryCodeToDependentCountry(district.nationalization) )) {
    initialValues.street = dependent.address;
    initialValues.city = dependent.city;
    initialValues.state = dependent.state;
    // country ??
    initialValues.homePhone = dependent.phoneNumber;

    // THIS IS A HACK-AROUND.
    // Dependent's postal codes allow upper/lower case letters but CMP's input mask (that is used in the enrollment form)
    // is uppercase. Copying a lower case postal code to an uppercase mask will cause the field to be blanked.
    // ASSUMPTION: CMP's zip mask does not support lower case char input.
    // this assumption is the least likely to break when copying data from dependent to enrollment
    if ( dependent.country === 'Canada' && dependent.zip){
      initialValues.zip = dependent.zip.toUpperCase();
    }
  }

  const formProps = {
    district,
    initialValues,
    showCaptcha: false,
    submit: (e: AddEnrollmentModel) => addEnrollment(district, e)
  };

  return <>
    {status === 'adding' && <Spinner size="large" />}
    {status === 'done' && <PageCenter>
      <div>
        <h1>You're all done!</h1>
        <p>Your enrollment request has been submitted successfully.</p>
      </div>
    </PageCenter>}
    {status !== 'done' && <EnrollmentForm {...formProps} />}
  </>;
};
