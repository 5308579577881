import React from 'react';
import { Formik } from 'formik';
import { Form, Grid, VerticalCell, ValidationErrors, Field, Cell, FlexSpacer, Button, PageCenter, Spinner } from '~/ui';
import * as Yup from 'yup';
import { useResendActivationEmail } from '../hooks/useResendActivationEmail';
import { Link } from 'react-router-dom';
import { Registered } from './Registered';

export const InvalidActivationToken = () => {
  const [status, requestEmail] = useResendActivationEmail();

  switch (status) {
    case 'alreadyActivated':
      return <>
        <h1>Activation error</h1>
        This account has already been activated. You can access it from the <Link to="/link">login page</Link>
      </>;
    case 'requesting':
      return <PageCenter>
        <Spinner />
        <span>Please wait...</span>
      </PageCenter>;
    case 'done':
      return <Registered />;
    case 'invalidEmail':
    case 'error':
      return <>
        <h1>Activation error</h1>
        <p>We couldn't resend an activation email to you.</p>
      </>;
  }

  return <>
    <h1>Activation error</h1>
    <p>
      Your account could not be activated for one of the following reasons:
      <ul>
        <li>This link has expired.</li>
        <li>This link has already been used.</li>
        <li>This link is invalid.</li>
      </ul>
    </p>
    <p>
      To finish your registration please enter the e-mail address you've used to register
      and click on the button below to send a new activation email.
    </p>
    <Formik
      initialValues={{ email: '' }}
      onSubmit={({ email }) => requestEmail(email)}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required('Required')
      })}
      validateOnBlur={false}
    >
      {() => <Form>
        <Grid>
          <VerticalCell>
            <ValidationErrors />
          </VerticalCell>
          <VerticalCell>
            <Field type="email" name="email" placeholder="Email" />
          </VerticalCell>
          <Cell>
            <FlexSpacer />
            <Button type="submit" kind="primary" disabled={status === 'requesting'}>Reset password</Button>
          </Cell>
        </Grid>
      </Form>}
    </Formik>
  </>;
};
