import styled from 'styled-components';
import { Theme } from '../Theme';

interface Props {
  cols?: number;
  gap?: 'small' | 'large';
}

export const Grid = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(${p => p.cols || 1}, 1fr);
  grid-gap: ${p => p.gap === 'small' ? Theme.SmallGap : Theme.Gap};
  /* & > * {
    overflow: hidden;
  } */
`;
