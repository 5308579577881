import React, { ReactNode } from 'react';
import { withLayout } from '~/parentPortal/hoc/withLayout';
import { Box, PageCenter, Spinner } from '~/ui';
import { useActivateAccount } from '../hooks/useActivateAccount';
import { InvalidActivationToken } from './InvalidActivationToken';
import { Link } from 'react-router-dom';

export const Activate = withLayout(null, () => {
  const params = new URLSearchParams(location.search);
  const status = useActivateAccount(params.get('token'));

  let content: ReactNode;
  switch (status) {
    case 'requesting':
      content = <PageCenter>
        <Spinner size="large" />
        <span>Please wait...</span>
      </PageCenter>;
      break;
    case 'invalidToken':
    case 'error':
      content = <InvalidActivationToken />;
      break;
    case 'done':
      content = <>
        <h1>Account activated</h1>
        <p>Your acount was activated successfully.</p>
        <p>Click <Link to="/login">here</Link> to sign in.</p>
      </>;
  }

  return <PageCenter>
    <Box width="medium">
      {content}
    </Box>
  </PageCenter>;
});
