import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { District } from '~/customers/District';
import { useNotify } from '~/notifications';
import { post, put } from '~/shared/http';
import { listEnrollments } from '.';
import { AddEnrollmentModel } from './models/AddEnrollmentModel';
import { State } from './state';

export function useDependentEnrollments(id: number | string) {
  const enrollments = useSelector((s: State) => s.enrollments) || [];
  return enrollments.filter(e => e.dependentId.toString() === id.toString());
}

export function useDependentEnrollment(id: number | string, guid: string) {
  const enrollments = useDependentEnrollments(id);
  return enrollments.find(e => e.guid === guid);
}

export type AddEnrollmentStatus = 'adding' | 'done' | 'error';

export function useAddEnrollment() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<AddEnrollmentStatus>();
  const { error } = useNotify();

  function unexpectedError() {
    error('An unexpected error has occurred while trying to submit the enrollment.');
  }

  async function addEnrollment(district: District, enrollment: AddEnrollmentModel) {
    setStatus('adding');
    try {
      // const response = await post(`/api/enr/${district.shortname}/enroll`, enrollment.serialize());
      const response = await post(`/api/enr/api/v2/enrollments`, enrollment.serialize());
      if (response.status === 200) {
        setStatus('done');
        dispatch(listEnrollments());
      }
      else {
        unexpectedError();
        setStatus('error');
      }
    }
    catch (e) {
      unexpectedError();
      setStatus('error');
    }
  }

  return [addEnrollment, status] as [typeof addEnrollment, AddEnrollmentStatus];
}

export type UpdateEnrollmentStatus = 'updating' | 'done' | 'error';

export function useUpdateEnrollment() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<UpdateEnrollmentStatus>();
  const { error, success } = useNotify();

  function unexpectedError() {
    error('An unexpected error has occurred while trying to update the enrollment.');
  }

  function done() {
    setStatus('done');
    dispatch(listEnrollments());
    success('The enrollment was updated successfully');
  }

  async function updateEnrollment(enrollment: AddEnrollmentModel) {
    setStatus('updating');
    try {
      const response = await put(`/api/enr/api/v2/enrollments/${enrollment.guid}`, enrollment.serializeForUpdate());
      if (response.status === 200) {
        if (!response.bodyUsed) {
          done();
        }
        else {
          const data = await response.json();

          const errors: string[] = (data.alerts || [])
            .filter((a: any) => a.error)
            .map((a: any) => a.message);

          if (data.success !== false && !errors.length) {
            done();
          }
          else {
            if (!errors.length) {
              unexpectedError();
            }
            else {
              error(errors);
            }
            setStatus('error');
          }
        }
      }
      else {
        unexpectedError();
        setStatus('error');
      }
    }
    catch (e) {
      unexpectedError();
      setStatus('error');
    }
  }

  return [updateEnrollment, status] as [typeof updateEnrollment, UpdateEnrollmentStatus];
}
