import { Dispatch, Reducer } from 'redux';
import { get } from '~/shared/http';
import { UserInfo, AuthState } from '../State';

const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
const USER_INFO_FAILURE = 'USER_INFO_FAILURE';
const USER_NOT_ACTIVATED = 'USER_NOT_ACTIVATED';

const userInfoRequest = () => ({
  type: USER_INFO_REQUEST
});

const userInfoSuccess = (userInfo: UserInfo, cmpAccess: boolean, enrAccess: boolean) => ({
  type: USER_INFO_SUCCESS,
  userInfo,
  cmpAccess,
  enrAccess
});

const userInfoError = (error: string) => ({
  type: USER_INFO_FAILURE,
  error
});

const userNotActivated = () => ({
  type: USER_NOT_ACTIVATED
});

export function getUserInfo() {
  return async (dispatch: Dispatch) => {
    await dispatch(userInfoRequest());
    const response = await get('/api/am/userdata');
    const body = await response.json();
    if (response.status === 200) {
      const {
        ID,
        Email,
        FirstName,
        MiddleName,
        LastName,
        DOB,
        HomePhone,
        Street,
        Country,
        State,
        City,
        Zip,
        Roles,
        Initialized
      } = body;

      if (!Initialized) {
        // Kind of odd but the Initialized flag denotes that the user has not
        // verified their email. The APIs are happy accepting calls from
        // non-initialized users, but the login screen doesn't want that and
        // shows an error message instead.
        await get('/api/am/auth/logout');
        await dispatch(userNotActivated());
        sessionStorage.removeItem('authToken');
      }
      else {

        const cmpAccess = !!(Roles || []).find((r: any) => r.RoleCode === 100 || r.RoleCode === 200);
        const enrAccess = !!(Roles || []).find((r: any) => r.RoleCode >= 300 || r.RoleCode < 400);

        await dispatch(userInfoSuccess(
          {
            id: ID,
            email: Email,
            firstName: FirstName || '',
            middleName: MiddleName || '',
            lastName: LastName || '',
            birthDate: DOB || '',
            phoneNumber: HomePhone || '',
            address: Street || '',
            country: Country || 'USA',
            state: State || '',
            city: City || '',
            zip: Zip || ''
          },
          cmpAccess,
          enrAccess
        ));
      }
    }
    else {
      await dispatch(userInfoError(body.message));
    }
  };
}

export const getUserInfoReducer: Reducer<AuthState> = (state, action) => {
  switch (action.type) {
    // User Info Request
    case USER_INFO_SUCCESS:
      return {
        ...state,
        initialized: true,
        authenticated: true,
        userInfo: action.userInfo,
        cmpAccess: action.cmpAccess,
        enrAccess: action.enrAccess
      };
    case USER_INFO_FAILURE:
      return {
        ...state,
        initialized: true,
        authenticated: false,
        userInfo: {}
      };
    case USER_NOT_ACTIVATED:
      return {
        authenticated: false,
        initialized: true,
        notActivated: true
      };
    default:
      return state || {};
  }
};
