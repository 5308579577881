export class Theme {

  // #region Colors
  static readonly Grey = '#6b7e83';
  static readonly DarkGrey = '#313e42';
  static readonly LightGrey = '#d3d3d3';
  static readonly Primary = '#f79923';
  static readonly Accent = '#57d1e5';
  static readonly LightAccent = '#c7e3e8';
  static readonly Background = '#c3c3c3';
  static readonly LightBackground = '#f2f2f2';
  static readonly Error = 'darkred';
  static readonly BoxShadow = '#00000057';
  // #endregion

  // #region Measurements
  static readonly Gap = '20px';
  static readonly SmallGap = '8px';
  static readonly LargeGap = '40px';
  static readonly Padding = '40px';
  // #endregion
}
